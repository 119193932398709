import styled from "styled-components"

export const Wrapper = styled.div``

export const DefaultContainer = styled.div<{aspectRatio:number,hideOnPortrait:boolean}>`
	position: relative;
	height: 0;
	padding-top: ${({aspectRatio}) => aspectRatio * 100}%;

	${({hideOnPortrait}) => hideOnPortrait ? `
		@media (orientation: portrait) {
			display: none;
		}
	` : ``}
`

export const Video = styled.video`
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;	
`

export const PortraitContainer = styled.div<{aspectRatio:number}>`
	position: relative;
	height: 0;
	padding-top: ${({aspectRatio}) => aspectRatio * 100}%;	

	@media (orientation: portrait) {
		display: block;
	}
`