import React, { PropsWithChildren } from 'react';
import rtlDetect from 'rtl-detect';
import { SitePageContextAllLocales, SitePageContextAlternateLocales } from '../common/types/graphql';
import { createCtx } from './create';

const DEFAULT_LOCALE = 'fr-LY';

type LocaleProps = {
  locale: string;
  defaultLocale: string;
  alternateLocales: SitePageContextAlternateLocales[];
  allLocales: SitePageContextAllLocales[];
  isLocalized: boolean;
  isSingleCountry: boolean;
  isRTL: boolean;
  direction: string;
};

const [useCtx, CtxProvider] = createCtx<LocaleProps>();

type LocaleProviderProps = PropsWithChildren<Partial<Omit<LocaleProps, 'isRTL'>>>;

const LocaleProvider = ({
  children,
  locale = DEFAULT_LOCALE,
  defaultLocale = DEFAULT_LOCALE,
  alternateLocales = [],
  allLocales = [],
  isLocalized = false,
  isSingleCountry = false,
}: LocaleProviderProps) => {
  const isRTL = rtlDetect.isRtlLang(locale) ?? false;
  const direction = rtlDetect.getLangDir(locale);
  return (
    <CtxProvider value={{ locale, defaultLocale, alternateLocales, allLocales, isLocalized, isSingleCountry, isRTL, direction }}>
      {children}
    </CtxProvider>
  );
};

const useLocale = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }
  return context.locale;
};

const useDefaultLocale = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useDefaultLocale must be used within a LocaleProvider');
  }
  return context.defaultLocale;
};

const useLanguage = () => {
  const context = useCtx();
  if (context === undefined || context.locale === undefined) {
    throw new Error('useLanguage must be used within a LocaleProvider');
  }
  return context.locale ? context.locale.split("-")[0] : undefined;
};

const useAlternateLocales = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useAlternateLocales must be used within a LocaleProvider');
  }
  return context.alternateLocales;
};

const useAllLocales = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useAllLocales must be used within a LocaleProvider');
  }
  return context.allLocales;
};

const useIsLocalized = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useIsLocalized must be used within a LocaleProvider');
  }
  return context.isLocalized;
};

const useIsRTL = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useIsRTL must be used within a LocaleProvider');
  }
  return context.isRTL;
};

const useDirection = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useDirection must be used within a LocaleProvider');
  }
  return context.direction;
};

const useIsSingleCountry = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useIsSingleCountry must be used within a LocaleProvider');
  }
  return context.isSingleCountry;
};

export { LocaleProvider, useLocale, useDefaultLocale, useLanguage, useIsLocalized, useIsSingleCountry, useIsRTL, useAlternateLocales, useAllLocales, useDirection };
