import React from "react";
import { Container, ContentContainer, FooterContainer, FooterInHeaderContainer, HeaderContainer } from "./card.styles";
import { CardProps } from "./card.types";


export const Card = ({ children, header, footer, mobileLayout, desktopLayout }:CardProps) => {
  
  return (
    <Container mobileLayout={mobileLayout} desktopLayout={desktopLayout}>
      {header && 
        <HeaderContainer>
          {header}
          <FooterInHeaderContainer>{footer}</FooterInHeaderContainer>
        </HeaderContainer>
      }
      <ContentContainer>
        {children}
      </ContentContainer>
      {footer && 
        <FooterContainer>
          {footer}
        </FooterContainer>
      }
    </Container>
  )
}