import { useState, useEffect } from "react";
import { PopupStatus } from "./popup.types";

function getStorageValue(id) {
  // getting stored value
  if (typeof window !== "undefined") {
    const key = `popup_${id}`;
    const saved = localStorage.getItem(key);
    const initial = saved ? JSON.parse(saved) : {
      value: PopupStatus.INITIALIZED,
      timestamp: Date.now()
    };
    return initial;
  }

  return undefined;
}

export const usePopupStatus = (id) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(id);
  });

  useEffect(() => {
    // storing input name
    const key = `popup_${id}`;
    localStorage.setItem(key, JSON.stringify(value));
  }, [id, value]);

  return [value, setValue];
};