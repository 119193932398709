import { Theme } from "../../../common/types";
import { DatoCmsMailchimp } from "../../../common/types/graphql";

export interface PopupProps {
  id:string;
  originalId: string;
  heading: string;
  text: string;
  illustration: {
    url: string;
  }
  delay: number;
  theme: Theme;
  mailchimp?: DatoCmsMailchimp;
}

export enum PopupStatus {
  VIEWED = 'Viewed',
  DISMISSED = 'Dismissed',
  COMPLETED = 'Completed', 
  INITIALIZED = 'Initialized'
}