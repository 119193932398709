import React, { createContext, useContext } from 'react';

const Context = createContext({});

const DictionaryProvider = ({ children, dictionary }) => {
  return (
		<Context.Provider value={dictionary}>
			{children}
		</Context.Provider>
	);
};

const useDictionary = (id:any) => {
	const context = useContext(Context);
	if (context === undefined) {
		throw new Error('useDictionary must be used within a DictionaryProvider');
	}
	return context[id];
};


export { DictionaryProvider, useDictionary };