import styled from "styled-components"

export const Media = styled.div<{hideOnPortrait:boolean,portrait:boolean}>`
  ${({portrait,hideOnPortrait}) => portrait ? `
    display: none;
    @media (orientation: portrait) {
      display: block;
    }
  `:
  `
    display: block;
    ${hideOnPortrait ? `
      @media (orientation: portrait) {
        display: none;
      }
    ` : ``}
  `
  }
`
