import React, { Children, ReactNode, useEffect, useRef, useState } from "react"
import { useIsRTL } from "../../../context/locale";
import { useSlider } from "../../../hooks/useSlider";
import { getColumns, getColumnWidthAsNumber, getGutterAsNumber, getMaxWidthAsNumber, Size } from "../../../theme/layout";
import { Container, Content, Item } from "./slider.styles"
import { useCurrentScreenSize } from "../../../hooks/useCurrentScreenSize";
import { useCurrentScreenDimensions } from "../../../hooks/useCurrentScreenDimensions";

export interface SliderProps {
  children:ReactNode;
  className?:string;
  span:Span;
  indent:Indent;
  columns?:Columns;
  onActiveIndexChange?:Function;
}

export interface Span {
  [Size.SMALL]:number;
  [Size.MEDIUM]:number;
  [Size.LARGE]:number;
  [Size.XLARGE]:number;
  [Size.MAX]:number;
}

export interface Indent {
  [Size.SMALL]:number;
  [Size.MEDIUM]:number;
  [Size.LARGE]:number;
  [Size.XLARGE]:number;
  [Size.MAX]:number;
}

export interface Columns {
  [Size.SMALL]:number;
  [Size.MEDIUM]:number;
  [Size.LARGE]:number;
  [Size.XLARGE]:number;
  [Size.MAX]:number;
}

const getCurrentColumns = (size:Size,columns:Columns | undefined) => columns && columns[size] ? columns[size] : getColumns(size);
const getCurrentSpan = (size:Size,span:Span) => span[size];
const getCurrentIndent = (size:Size,indent:Indent) => indent[size];

export const Slider = ({ 
  children, 
  className,
  span,
  indent,
  columns,
  onActiveIndexChange
}:SliderProps) => {
  const count = Children.count(children);
  const size = useCurrentScreenSize() || Size.LARGE;
  const { screenWidth } = useCurrentScreenDimensions();

  let currentSpan = getCurrentSpan(size,span);
  let currentIndent = getCurrentIndent(size,indent);
  let currentColumns = getCurrentColumns(size,columns);

  const maxIndex = Math.max(0,count - Math.floor((currentColumns - currentIndent) / currentSpan));
  const canvasWidth = screenWidth ? Math.min(screenWidth,getMaxWidthAsNumber()) : 0;
  const columnWidth = getColumnWidthAsNumber(size,currentSpan,canvasWidth);
  const gutterWidth = getGutterAsNumber(size);
  const rtl = useIsRTL();

  useEffect(() => {
    if(onActiveIndexChange){
      onActiveIndexChange({index:0,maxIndex});
    }
  },[]);

  const containerRef = useRef();
  const contentRef = useRef();

	const { handlers } = useSlider(contentRef, maxIndex, columnWidth, gutterWidth, rtl, onActiveIndexChange);

	let containerProps = {selectable:true};

	containerProps = {...handlers,selectable:true};

  return (
    <Container ref={containerRef} className={className} {...containerProps}>
      <Content ref={contentRef} length={count} span={span} indent={indent}>
        {children}
      </Content>
    </Container>
  )
}