import styled from 'styled-components';
import { CONTAINER, getLayout, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, Layout, Size, VerticalSpaceVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

const units = (multiplier: number) => getUnitAsPixels({ multiplier });
const spaces = (multiplier: number) => getSpacerAsPixels({ multiplier });

export const Container = styled.div`  
  height: 100%;
  overflow: scroll;
`;

export const InputContent = styled.div`
  ${CONTAINER}
`

export const InputContainer = styled.div`
  ${getLayout(Layout.BLOCK)}
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
  }
`;

export const InputInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.primary.hex};

  svg {
    font-size: ${units(2.5)};
    stroke: ${({ theme }) => theme.primary.hex};
  }
`

export const Input = styled.input<any>`
  width: 100%;
  ${getTypography(Type.BODY_1)};
`;

export const Panel = styled.div<any>`
  overflow: hidden;
`;

export const ResultsContainer = styled.div`
  ${CONTAINER}
`;

export const ResultsSummary = styled.div`
  ${getLayout(Layout.BLOCK)}
`

export const ResultsCount = styled.div`
  ${getTypography(Type.BODY_2)}
`;

export const ResultsEmpty = styled.div`
  ${getTypography(Type.BODY_2)}
`;
