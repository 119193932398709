import { motion } from 'framer-motion';
import styled from 'styled-components';
import { getHeaderAsPixels, getMediaQuery, Size } from '../../../theme/layout';
import AutocompleteBase from './autocomplete.component';

export const Container = styled(motion.div)<any>`
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 10;
  padding-top: ${getHeaderAsPixels(Size.SMALL)};
  background-color: ${({ theme }) => theme.background.hex};
  color: ${({ theme }) => theme.primary.hex};
  
  ${getMediaQuery(Size.MEDIUM)} {
    padding-top: ${getHeaderAsPixels(Size.MEDIUM)};
  }

  ${getMediaQuery(Size.LARGE)} {
    padding-top: ${getHeaderAsPixels(Size.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)} {
    padding-top: ${getHeaderAsPixels(Size.XLARGE)};
  }

  ${getMediaQuery(Size.MAX)} {
    padding-top: ${getHeaderAsPixels(Size.MAX)};
  }
`;