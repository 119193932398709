import React, { forwardRef, SVGProps } from 'react';

const SvgClose = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path d="M1 1l18 18M19 1L1 19" strokeWidth={2} />
    </svg>
  );
});

export default SvgClose;