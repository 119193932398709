import { graphql, useStaticQuery } from "gatsby";
import { AnimatePresence } from 'framer-motion';
import { get } from "lodash";
import React, { useState } from "react";
import { PageQuery } from "../../../common/types/graphql";
import { useAllLocales, useAlternateLocales, useLocale } from "../../../context/locale";
import { Arrow, Container, CurrentLocale, Flag, LocaleItem, LocaleLink, LocaleList } from "./locale.styles";
import { useDictionary } from "../../../context/dictionary";

export const MenuLocale = () => {
  const locale = useLocale();
  const alternateLocales = useAlternateLocales();
  const allLocales = useAllLocales();


  const data = useStaticQuery(graphql`
    query LocaleMenu {
      dictionary:allDatoCmsDictionary {
        nodes {
          locale
          nameCountry
          nameLanguage
        }
      }
      navigation:allDatoCmsNavigation {
        nodes {
          locale
          locales {
            ...NavigationLinkLocale
          }
        }
      }
    }
  `);



  const navigation = get(data, 'navigation.nodes', []).find((n) => n.locale === locale);

  const dictionary = get(data, 'dictionary.nodes', []).find((n) => n.locale === locale);

  const currentCountry = locale.split('-')[1];

  const [open, setOpen] = useState(false);

  if(allLocales.length > 1){
    return (
      <Container>
        <CurrentLocale onClick={() => setOpen(!open)} isOpen={open}>
          <Flag src={`/images/flags/${currentCountry}.svg`} />
          {dictionary.nameCountry} / {dictionary.nameLanguage}
          <Arrow width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 8.00012L10 12.0001L14 8.00012" />
          </Arrow>
        </CurrentLocale>
        <AnimatePresence>
          {open ? (
            <LocaleList initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              {navigation.locales.map((l) => {
                return <LocaleItem><LocaleLink href={l.url}><Flag src={`/images/flags/${l.countryCode}.svg`} />{l.countryName} / {l.languageName}</LocaleLink></LocaleItem>
                
                
              })}
            </LocaleList>
          ) : null}
        </AnimatePresence>
      </Container>
    )
  }

  return null;
}