import React from 'react'
import { MediaInternal } from '../../../common/types';

import { ResponsiveImage } from './responsiveImage.component';
import { Media } from './responsiveMedia.styles';
import { ResponsiveMediaContentProps, ResponsiveMediaProps } from './responsiveMedia.types';
import { ResponsiveVideo } from './responsiveVideo.component';



const Content = ({source,sizes,sizeOptions,className,onClick,hideOnPortrait,portrait}:ResponsiveMediaContentProps) => {
	if(source.video){
		return (
				<Media as={ResponsiveVideo} onClick={onClick} className={className} defaultSource={source} hideOnPortrait={hideOnPortrait} portrait={portrait} />
		)
	}
	else if(source.gatsbyImageData){
		return (
				<Media as={ResponsiveImage} onClick={onClick} className={className} defaultSource={source} sizes={sizes} sizeOptions={sizeOptions} hideOnPortrait={hideOnPortrait} portrait={portrait} />
		)
	}

	return null;
}

export const ResponsiveMedia = ({defaultSource,portraitSource,sizes,sizeOptions,className,onClick}:ResponsiveMediaProps) => {
	if(defaultSource){
		return (
			<>
				<Content onClick={onClick} className={className} source={defaultSource} sizes={sizes} sizeOptions={sizeOptions} portrait={false} hideOnPortrait={!!portraitSource} />
				{portraitSource && <Content onClick={onClick} className={className} source={portraitSource} sizes={sizes} sizeOptions={sizeOptions} portrait={true} hideOnPortrait={false} />}
			</>
		)
	}
	
	return null;
}