import React, { useState } from 'react';
import { Button, Email, Message, ErrorMessage } from './mailchimp.styles';
import { MailchimpProps, FormState } from './mailchimp.types';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export const Mailchimp = ({messageFail,messageSuccess,endpoint,placeholderEmail,labelButton,onSubmitted = () => {}}:MailchimpProps) => {
  const [email, setEmail] = useState('');
  const [formState, setFormState] = useState(FormState.DEFAULT);
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormState(FormState.PROCESSING);

    const response = await addToMailchimp(email, null, endpoint && endpoint.length > 0 ? endpoint : null) 
    if (response.result === 'success') {
			setFormState(FormState.SUCCESS);
      onSubmitted()
		} else {
			setFormState(FormState.FAIL);
      setError(response.msg);
		}
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  if(formState === FormState.FAIL){
    return (
      <>
        <Message>{messageFail}</Message>
        <ErrorMessage dangerouslySetInnerHTML={{__html:error}} />
      </>
    )
  }
  else if(formState === FormState.SUCCESS){
    return (
      <Message>{messageSuccess}</Message>
    )
  }

  return (
    <form action={endpoint} onSubmit={handleSubmit}>
      <Email placeholder={placeholderEmail} value={email} onChange={handleEmailChange} />
      <Button disabled={formState === FormState.PROCESSING} value={labelButton} />
    </form>
  )
};