import styled from 'styled-components';
import { getMediaQuery, getUnitAsPixels, Size } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { ArticleCardLayout } from './articleCard.types';
import { LinkInternal } from '../../core/link';
import { CardLayout } from '../../core/card/card.types';
import { ResponsiveImage } from '../../core/responsiveMedia';

export const HeadingInner = styled.span`
  overflow: hidden;
  display: block;
`

export const Heading = styled(LinkInternal)<{featured:boolean,mobileLayout:CardLayout,desktopLayout:CardLayout}>`
  ${({featured}) => featured ? getTypography(Type.MEDIUM_HEADING) : getTypography(Type.SMALL_HEADING)}
  color: ${({ theme }) => theme.primary.hex};
  text-decoration: none;
  display: block;
  overflow: hidden;
  transition: color 0.3s;
  padding-bottom: ${getUnitAsPixels()};

  &:hover{
    color: ${({theme}) => theme.secondary.hex};
  }

  ${HeadingInner}{
    height: ${({mobileLayout}) => mobileLayout === CardLayout.VERTICAL ? `40px` : `auto`};
  }
  
  
  ${getMediaQuery(Size.LARGE)}{
    ${HeadingInner}{
      height: ${({desktopLayout}) => desktopLayout === CardLayout.VERTICAL ? `40px` : `auto`};
    }
  }
`

export const ThumbnailContainer = styled(LinkInternal)`
  position: relative;
  display: block;

  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 2;
  }

  &:hover{
    &:after{
      opacity: 1;
    }
  }
`

export const Thumbnail = styled(ResponsiveImage)`
  user-select: none;
  position: relative;
  z-index: 1;
`

export const Meta = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${getUnitAsPixels()};
  color: ${({theme}) => theme.primary.hex};

  &:before{
    content: "";
    width: ${getUnitAsPixels()};
    height: ${getUnitAsPixels()};
    background-color: ${({theme}) => theme.primary.hex};
    margin-inline-end: ${getUnitAsPixels()};
  }

  > * {
    margin-inline-end: ${getUnitAsPixels()};
  }
`

export const Topic = styled(LinkInternal)`
  ${getTypography(Type.LABEL_1)}
  color: ${({theme}) => theme.secondary.hex};
  text-decoration: none;
  transition: color 0.3s;

  &:hover{
    color: ${({theme}) => theme.primary.hex};
  }
`

export const Author = styled(LinkInternal)`
  color: ${({theme}) => theme.secondary.hex};
  text-decoration: none;
  transition: color 0.3s;

  &:hover{
    color: ${({theme}) => theme.primary.hex};
  }
`

export const Published = styled.div`
  ${getTypography(Type.LABEL_2)}
`

export const Caption = styled.div`
  margin-top: ${getUnitAsPixels()};
  color: ${({theme}) => theme.primary.hex};
`

export const CaptionLabel = styled.span`
  ${getTypography(Type.LABEL_2)}

  &:after{
    content: " ";
  }
`

export const CaptionValue = styled.span`
  ${getTypography(Type.LABEL_1)}
`

export const Badge = styled.span<{rtl:boolean,selected:boolean}>`
  ${getTypography(Type.LABEL_2)}
  background-color: ${({ theme, selected }) => selected ? theme.colors.common.primary.hex : theme.primary.hex};
  color: ${({ theme, selected }) => selected ? theme.colors.common.dark.hex : theme.background.hex};
  position: absolute;
  bottom: 0;
  ${({ rtl }) => rtl ? `right: 0;` : `left: 0;`}
  padding: ${getUnitAsPixels()} ${getUnitAsPixels({multiplier:2})};
  z-index: 3;
`