import React, { ReactNode, useState } from "react"

import { Size } from "../../../theme/layout";
import { Btn, ButtonContainer, Container, ListContent, ListItem, ProgressBarContainer, ProgressBarContent, ProgressBarSegment, SliderContent, SliderItem} from "./cardContainer.styles"
import { BlockHeader } from "../../block/header";
import { Button } from "../../core/button";
import { ButtonVariant } from "../../core/button/button.types";
import { CardContainerContentProps, CardContainerItemProps, CardContainerProps } from "./cardContainer.types";
import { useIsRTL } from "../../../context/locale";

export const CardContainer = ({ heading, heading2, subheading, link, button, expandable, onButtonClick = () => {}, children }:CardContainerProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  }
  
  return (
    <Container>
      <BlockHeader heading={heading} heading2={heading2} subheading={subheading} expandable={expandable} expanded={expanded} onExpandClick={handleExpandClick} />
      {(!expandable || expanded) && 
        <>
        {children}
        {(link || button) && 
          <ButtonContainer>
            {link ? <Button link={link} variant={ButtonVariant.TERTIARY} /> : <Btn onClick={onButtonClick}>{button}</Btn>}
          </ButtonContainer>
        }
        </>
      }
    </Container>
  )
}

export const CardContainerProgress = ({index,maxIndex}) => {
  const isRTL = useIsRTL();

  if(index !== undefined && maxIndex !== undefined && maxIndex > 0){
    return (
      <ProgressBarContainer>
        <ProgressBarContent rtl={isRTL} index={index} maxIndex={maxIndex} />
      </ProgressBarContainer>
    )
  }
  
  return null
}

export const CardContainerListContent = ({children}:CardContainerContentProps) => <ListContent>{children}</ListContent>
export const CardContainerListItem = ({children,featured}:CardContainerItemProps) => <ListItem featured={featured}>{children}</ListItem>
export const CardContainerSliderContent = ({children,onActiveIndexChange}:CardContainerContentProps) => <SliderContent onActiveIndexChange={onActiveIndexChange} span={{[Size.SMALL]:6,[Size.MEDIUM]:4,[Size.LARGE]:3,[Size.XLARGE]:3,[Size.MAX]:3}} indent={{[Size.SMALL]:0,[Size.MEDIUM]:0,[Size.LARGE]:0,[Size.XLARGE]:0,[Size.MAX]:0}} columns={{[Size.SMALL]:8,[Size.MEDIUM]:12,[Size.LARGE]:9,[Size.XLARGE]:9,[Size.MAX]:9}}>{children}</SliderContent>
export const CardContainerSliderItem = ({children,featured}:CardContainerItemProps) => <SliderItem featured={featured}>{children}</SliderItem>