import styled from 'styled-components';
import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';
import { ThemeType } from '../../../theme/color';

export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
	${reset}

	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-results-button,
	input[type="search"]::-webkit-search-results-decoration {
		-webkit-appearance:none;
	}
	input.hide-clear[type=search]::-ms-clear,
	input.hide-clear[type=search]::-ms-reveal {
		display: none;
		width: 0;
		height: 0; 
	}

	html, body {
		background-color: ${({ theme }) => theme.background.hex};
		color: ${({ theme }) => theme.primary.hex};
		box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
		touch-action: pan-y pinch-zoom;
	}

	*, *:before, *:after {
		box-sizing: inherit;
		user-drag: none;
	}

	input {
		user-select: auto;
		appearance: none;
		border-radius: 0;
		border:none;
    background-image:none;
    background-color:transparent;
    box-shadow: none;
		outline: none;
	}

	button {
		all: unset;
		background: transparent;
		border: none;
	}
`;

export const Container = styled.div``;