import React, { createContext, PropsWithChildren, useContext } from 'react';
import { Theme } from '../common/types';
import { createCtx } from './create';

type BrandProps = {
  themes: {[key:string]: Theme};
	images: {[key:string]: {url:string}};
	colors: {[key:string]: {hex:string}};
};

const Context = createContext({
  themes: {},
  images: {},
  colors: {}
});

const BrandProvider = ({
  children,
  themes,
	colors,
	images
}: PropsWithChildren<BrandProps>) => {
  
  return (
    <Context.Provider value={{ themes, colors, images }}>
      {children}
    </Context.Provider>
  );
};

const useBrandTheme = (name:string) => {
  const context:BrandProps = useContext(Context);
  if (context === undefined) {
    throw new Error('useBrandTheme must be used within a BrandProvider');
  }
	const value = context.themes[name];
  return value;
};

const useBrandColor = (name:string) => {
  const context:BrandProps = useContext(Context);
  if (context === undefined) {
    throw new Error('useBrandColor must be used within a BrandProvider');
  }
	const value = context.colors[name];
  return value;
};

const useBrandImage = (name:string) => {
  const context:BrandProps = useContext(Context);
  if (context === undefined) {
    throw new Error('useBrandImage must be used within a BrandProvider');
  }
	const value = context.images[name];
  return value;
};

export { BrandProvider, useBrandTheme, useBrandColor, useBrandImage };