import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { boolean } from "zod"

export const DefaultImage = styled(GatsbyImage)<{$hideOnPortrait:boolean}>`
	display: block;
	// position: absolute;
	// top: 0;
	// left: 0;
	// width: 100%;
	// height: 100%;

	${({$hideOnPortrait}) => $hideOnPortrait ? `
		@media (orientation: portrait) {
			display: none;
		}
	` : ``}
`

export const FallbackImage = styled.img`
		display: block;
		object-fit: cover;
		width: 100%;
`

export const PortraitImage = styled(GatsbyImage)`
	display: none;
	// position: absolute;
	// top: 0;
	// left: 0;
	// width: 100%;
	// height: 100%;

	@media (orientation: portrait) {
		display: block;
	}
`