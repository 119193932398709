import { useEffect, useState } from 'react';


function getHeight(){
	if(typeof window !== 'undefined'){
		return window.innerHeight;
	}

	return undefined;
}

function getWidth(){
	if(typeof window !== 'undefined'){
		return window.innerWidth;
	}

	return undefined;
}

export function useCurrentScreenDimensions({wait=100}={wait:100}) {
	const [screenWidth, setScreenWidth] = useState(getWidth());
	const [screenHeight, setScreenHeight] = useState(getHeight());
	let throttleTimeout: number | null = null;

	const update = () => {
		throttleTimeout = null;
		setScreenWidth(getWidth());
		setScreenHeight(getHeight());
	}

	useEffect(() => {
		const change = () => {
			if (wait) {
				if (throttleTimeout === null) {
					throttleTimeout = window.setTimeout(update, wait)
				}
			} else {
				update()
			}
		}
		
		if(typeof window !== 'undefined'){
			window.addEventListener('resize', change);
			return () => {
				window.removeEventListener('resize', change);
			};
		}
	},[]);

	return {screenWidth,screenHeight};
}