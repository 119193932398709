import Color from 'color';
import styled from 'styled-components';
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Message = styled.div`
  ${getTypography(Type.BODY_2)}
  color: ${({theme}) => theme.primary.hex};
`;

export const ErrorMessage = styled.p`
  ${getTypography(Type.BODY_2)}
  color: ${({theme}) => theme.primary.hex};

  a{
    color: ${({theme}) => theme.primary.hex};
  }
`;

export const Email = styled.input.attrs(props => ({
  type: 'email',
  required: true,

}))`
  ${getTypography(Type.BODY_2)}  
  background-color: transparent;
  border: none;
  color: ${({theme}) => theme.primary.hex};
  border-bottom: 1px solid ${({theme}) => Color(theme.primary.hex).alpha(0.3).toString()};
  width: 100%;
  margin-bottom: ${getUnitAsPixels({multiplier:2})};
  border-radius: 0;
  appearance: none;

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getUnitAsPixels({multiplier:3})};
  }

  &:last-child{
    margin-bottom: 0;
  }

  &:focus{
    outline: none;
    border-bottom: 1px solid ${({theme}) => theme.primary.hex};
  }

  &::placeholder{
    color: ${({theme}) => Color(theme.primary.hex).alpha(0.3).toString()};
  }
`

export const Button = styled.input.attrs(props => ({
  type: 'submit',
}))`
  ${getTypography(Type.BUTTON_1)}
  padding: ${getSpacerAsPixels({multiplier:0.5})} ${getSpacerAsPixels()};
  display: block;
  text-decoration: none;
  background-color: transparent;
	color: ${({theme}) => theme.primary.hex};
  border: 1px solid ${({theme}) => theme.primary.hex};
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  border-radius: 0;

  &:hover{
    color: ${({theme}) => theme.background.hex};
    background-color: ${({theme}) => theme.primary.hex};
  }
`;