import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { Theme } from '../../../common/types';
import { useIsRTL, useLanguage, useLocale } from '../../../context/locale';
import { useBrandImage, useBrandTheme } from '../../../context/brand';
import useScroll from '../../../hooks/useScroll';
import { Menu } from '../menu';
import { BurgerIcon } from './burgerIcon.component';
import {
  Logo,
  LogoIcon,
  Lockup,
  LinkList,
  LinkListItem,
  LinkListLink,
  Controls,
  Branding,
  LockupImage,
  SearchButton,
  MenuButton,
  FixedBar,
  StickyBar,
  Container
} from './header.styles';
import { SearchIcon } from './searchIcon.component';
import { Search } from '../../core/search';

export type LinkProps = {
  label: string;
  href: string;
  tag?: string;
};

const TRANSITION = {
  ease: 'easeInOut',
  duration: 0.3,
};

const TOP = 400;

const Content = ({desktopForeground,mobileForeground,monochromeLogo,isTop,isLight,links,menuOpen,searchOpen,onSearchClick,onMenuClick,onSearchClose,onMenuClose}) => {
  const language = useLanguage();
  const logo = useBrandImage("logo");
  const lockup = useBrandImage("lockup");
  const rtl = useIsRTL();
  
  return (
    <>
    <Branding>
      <Logo to={`/${language}/`} isTop={isTop} isMonochrome={monochromeLogo} desktopForeground={desktopForeground} mobileForeground={mobileForeground}>
        <LogoIcon icon={logo.url} />
      </Logo>
      <Lockup to={`/${language}/`} isTop={isTop} isLight={isLight} desktopForeground={desktopForeground} mobileForeground={mobileForeground}>
        <LockupImage icon={lockup.url} rtl={rtl} />
      </Lockup>
    </Branding>

    <LinkList isTop={isTop} isLight={isLight} desktopForeground={desktopForeground} mobileForeground={mobileForeground}>
      {links.map((link, i) => (
        <LinkListItem key={i}>
          <LinkListLink link={link}><span>{link.label}</span></LinkListLink>
        </LinkListItem>
      ))}
    </LinkList>
    
    <Search isOpen={searchOpen} onClose={onSearchClose} />
    <Menu isOpen={menuOpen} onClose={onMenuClose} />
    
    <Controls>
      <SearchButton $isTop={isTop} $isOpen={searchOpen} $desktopForeground={desktopForeground} $mobileForeground={mobileForeground} onClick={onSearchClick}>
        <SearchIcon isOpen={searchOpen} />
      </SearchButton>
      <MenuButton $isTop={isTop} $isOpen={menuOpen} $desktopForeground={desktopForeground} $mobileForeground={mobileForeground} onClick={onMenuClick}>
        <BurgerIcon isOpen={menuOpen} />
      </MenuButton>
    </Controls>
    </>
  )
}

export const Header = ({desktopForeground,mobileForeground,monochromeLogo}) => {
  const locale = useLocale();
  
  const data = useStaticQuery(graphql`
    query Header {
      navigation:allDatoCmsNavigation {
        nodes {
          locale
          top {
            ...NavigationLinkInternal
            ...NavigationLinkExternal
          }
        }
      }
    }
  `);

  const navigation = get(data, 'navigation.nodes', []).find((n) => n.locale === locale);
  const links = get(navigation, 'top');
  const theme = useTheme() as Theme;
  const lightTheme = useBrandTheme("light");

  const { scrollIsTop, scrollDirection } = useScroll({top:TOP});
  
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const aniState = useMemo(() => {
    if (searchOpen) return 'search';
    if (scrollIsTop) return 'top';
    switch (scrollDirection) {
      case 1:
        return 'scrollingDown';
      case -1:
        return 'scrollingUp';
      default:
        return 'top';
    }
  }, [scrollIsTop, scrollDirection, searchOpen]);

  const isLight = theme.name === lightTheme.name;

  const template = ({ y }) => {
    return y === "0%" ? `none` : `translateY(${y})`
  }

  const handleSearchClick = (event) => {
    setMenuOpen(false);
    setSearchOpen((p) => !p);
  }

  const handleMenuClick = (event) => {
    setSearchOpen(false);
    setMenuOpen((p) => !p);
  }

  const handleSearchClose = () => {
    setSearchOpen(false);
  }

  const handleMenuClose = () => {
    setMenuOpen(false);
  }

  return (
    <Container initial="top" animate={aniState}>
      <FixedBar>
        <Content isTop={true} isLight={isLight} desktopForeground={desktopForeground} mobileForeground={mobileForeground} monochromeLogo={monochromeLogo} links={links} menuOpen={menuOpen} searchOpen={searchOpen} onSearchClick={handleSearchClick} onMenuClick={handleMenuClick} onSearchClose={handleSearchClose} onMenuClose={handleMenuClose}  />
      </FixedBar>
      <StickyBar
        variants={{
          search: {
            y: 0
          },
          top: {
            y: "-100%"
          },
          scrollingDown: {
            y: "-100%"
          },
          scrollingUp: {
            y: 0
          },
        }}
        transition={TRANSITION}
        transformTemplate={template}
      >
        <Content isTop={false} isLight={isLight} desktopForeground={desktopForeground} mobileForeground={mobileForeground} monochromeLogo={monochromeLogo} links={links} menuOpen={menuOpen} searchOpen={searchOpen} onSearchClick={handleSearchClick} onMenuClick={handleMenuClick} onSearchClose={handleSearchClose} onMenuClose={handleMenuClose} />
      </StickyBar>
    </Container>
  );
};

export default Header;
