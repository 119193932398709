import React, { createContext, useContext } from 'react';

const SectionContext = createContext({});

const SectionProvider = ({ children, paths }) => {
	return (
		<SectionContext.Provider value={paths}>
			{children}
		</SectionContext.Provider>
	);
};

const useSection = (id) => {
	const context = useContext(SectionContext);
	if (context === undefined) {
		throw new Error('useSection must be used within a SectionProvider');
	}
	return context[id];
};

const useSections = () => {
	const context = useContext(SectionContext);
	if (context === undefined) {
		throw new Error('useSections must be used within a SectionProvider');
	}
	return context;
};

export { SectionProvider, useSection, useSections };