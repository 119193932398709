import get from 'lodash/get';
import React, { ReactChild, ReactEventHandler, ReactNode, useState } from 'react';
import { useIsLocalized, useIsSingleCountry, useLocale } from '../../../context/locale';
import { useSections } from '../../../context/section';
import { getInternalURL } from '../../../common/link';

import { Link as GatsbyLink } from "gatsby"

export const LinkInternal = ({label,page,className,children,icon}:{label?:string,page:any,className?:string,children?:ReactNode,icon?:ReactNode}) => {
  const sections = useSections();
  const isLocalized = useIsLocalized();
  const isSingleCountry = useIsSingleCountry();
  const locale = useLocale();

  const [cursorX,setCursorX] = useState(null);
  
  const sectionId = get(page,"section.id");
  const pageType = get(page,"model.apiKey");
  const slug = get(page,"slug");
  const section = sections[sectionId];

  const url = getInternalURL({
    isLocalized,
    isSingleCountry,
    section,
    locale,
    pageType,
    slug
  });

  const handleMouseDown = (event) => {
    setCursorX(event.screenX);
  }

  const handleClick = (event) => {
    if(cursorX){
      const delta = Math.abs(event.screenX - cursorX);

      if(delta > 10) {
        event.preventDefault();
      }

      setCursorX(null);
    }
  }

  return <GatsbyLink to={url} activeClassName="active" draggable={false} onMouseDown={handleMouseDown} onClick={handleClick} onDragStart={(e) => {e.preventDefault()}} className={className}>{children ?? label}</GatsbyLink>
};