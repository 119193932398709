import React, { useEffect, useState } from "react";
import { daysAfterDate } from "../../../common/date";
import { useDictionary } from "../../../context/dictionary";
import { useIsRTL } from "../../../context/locale";

import { Size } from "../../../theme/layout";
import { Card } from "../../core/card";
import { CardLayout } from "../../core/card/card.types";
import { ResponsiveImage } from "../../core/responsiveMedia";
import { ThumbnailContainer, Heading, Badge, Label, Thumbnail } from "./showCard.styles";
import { ShowCardProps } from "./showCard.types";

const RECENT_DAYS = 7;

export const ShowCard = ({show, mobileLayout, desktopLayout}:ShowCardProps) => {
  const labelTvVideosCount = useDictionary("labelTvVideosCount");
  const labelTvUpdated = useDictionary("labelTvUpdated");
  const isRTL = useIsRTL();

  const [updated, setUpdated] = useState(false);

  const sizes = {
    [Size.SMALL]: {span: mobileLayout === CardLayout.VERTICAL ? 6 : 8},
    [Size.MEDIUM]: {span: mobileLayout === CardLayout.VERTICAL ? 4 : 8},
    [Size.LARGE]: {span: desktopLayout === CardLayout.VERTICAL ? 3 : 6},
    [Size.XLARGE]: {span: desktopLayout === CardLayout.VERTICAL ? 3 : 6},
    [Size.MAX]: {span: desktopLayout === CardLayout.VERTICAL ? 3 : 6},
  };

  useEffect(() => {
    const isSSR = typeof window === "undefined"    
    if(!isSSR && show.videos?.length > 0){
      setUpdated(show.videos.some(video => video.meta && daysAfterDate(video.meta.firstPublishedAt) <= RECENT_DAYS));
    }
  },[]);

  return (
    <Card 
      mobileLayout={mobileLayout}
      desktopLayout={desktopLayout}
      header={
        <>
          <Heading page={show}>{show.title}</Heading>
        </>
      }
    >
      {show.thumbnail && 
        <ThumbnailContainer page={show}>
          <Thumbnail container="span" defaultSource={show.thumbnail} sizes={sizes} alt={show.thumbnail.alt} />
          {updated && <Badge rtl={isRTL}>{labelTvUpdated}</Badge>}
        </ThumbnailContainer>
      }
    </Card>
  )
}