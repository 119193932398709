import { LinkExternal, LinkInternal } from "../../../common/types";

export interface ButtonProps{
  link: LinkExternal | LinkInternal;
  variant: ButtonVariant;
  size?: ButtonSize;
}

export enum ButtonVariant {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY"
}

export enum ButtonSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM"
}