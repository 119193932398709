import { AutocompleteState, createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import React, { forwardRef, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import * as z from 'zod';
import { getArticlePath, getTvShowPath } from '../../../common/link';
import { DatoCmsBlogArticle, DatoCmsTvShow } from '../../../common/types/graphql';
import { useDictionary } from '../../../context/dictionary';
import { useIsLocalized, useIsSingleCountry, useLocale } from '../../../context/locale';
import getSearchClient from '../../../services/algolia/searchClient';
import SvgSearch from '../../svg/search/search.component';
import {
  Container,
  Input,
  InputContainer,
  InputContent,
  InputInner,
  Panel,
  ResultsContainer,
  ResultsCount,
  ResultsEmpty,
  ResultsSummary,
} from './autocomplete.styles';
import AutocompleteCollection from './autocompleteCollection.component';

const DEFAULT_MAX_RESULTS = 1000;
const TV_SHOW_MAX_RESULTS = 6;

const searchClient = getSearchClient();

export type BaseItem = DatoCmsBlogArticle | DatoCmsTvShow;

type Props = {
  placeholder?: string;
};

const ContentTypeEnum = z.enum(['blog_article', 'tv_show'] as const);
export type ContentType = z.infer<typeof ContentTypeEnum>;

const Autocomplete = forwardRef<HTMLDivElement, Props>(({ ...restProps }, ref) => {
  
  const textSearchResultsMatch = useDictionary("textSearchResultsMatch");
  const textSearchNoResults = useDictionary("textSearchNoResults");
  const placeholderSearch = useDictionary("placeholderSearch");
  
  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<BaseItem> | null>(null);
  const locale = useLocale();
  const isLocalized = useIsLocalized();
  const isSingleCountry = useIsSingleCountry();
  const { getRootProps, getInputProps, getPanelProps, getListProps, getItemProps } = useMemo(
    () =>
      createAutocomplete<BaseItem>({
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        getSources() {
          return ContentTypeEnum.options.map((contentType) => ({
            sourceId: contentType,
            getItemInputValue({ item }) {
              return item.title;
            },
            getItems({ query }) {
              const results = getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: `${process.env.GATSBY_ALGOLIA_PREFIX}_${locale}`,
                    query,
                    params: {
                      filters: `contentType:${contentType}`,
                      hitsPerPage: contentType === 'tv_show' ? TV_SHOW_MAX_RESULTS : DEFAULT_MAX_RESULTS,
                    },
                  },
                ],
              });

              return results;
            },
            getItemUrl({ item }) {
              return item.contentType === 'tv_show' ? getTvShowPath({isLocalized,isSingleCountry,locale,slug:item.slug}) : getArticlePath({isLocalized,isSingleCountry,locale,slug:item.slug})
            },
          }));
        },
        placeholder: placeholderSearch,
        autoFocus: true,
        shouldPanelOpen: ({ state }) => state.query.length > 0,
        initialState: {
          query: '',
        },
        defaultActiveItemId: 0,
        debug: true,
      }),
    []
  );

  const resultsCount = useMemo(
    () => (!autocompleteState ? 0 : autocompleteState.collections.reduce((acc, v) => acc + v.items.length, 0)),
    [autocompleteState?.collections]
  );

  return (
    
      <Container ref={ref} {...getRootProps()} {...restProps}>
        <InputContainer>
          <InputContent>
            <InputInner>
              <Input {...getInputProps({ inputElement: null })} />
              <SvgSearch />
            </InputInner>
          </InputContent>
        </InputContainer>

        <Panel {...getPanelProps()}>
          {!autocompleteState
            ? null
            : autocompleteState.isOpen && (
                <>
                  <ResultsContainer>
                    <ResultsSummary>
                      {resultsCount > 0 ? (
                        <ResultsCount>{`${resultsCount} ${textSearchResultsMatch} '${autocompleteState.query}'`}</ResultsCount>
                      ) : (
                        <ResultsEmpty>{textSearchNoResults}</ResultsEmpty>
                      )}
                    </ResultsSummary>
                  </ResultsContainer>
                  <div>
                    {autocompleteState.collections.map((collection, index) => (
                      <AutocompleteCollection key={index} {...{ collection, index, getListProps, getItemProps }} />
                    ))}
                  </div>
                </>
              )}
        </Panel>
      </Container>
  );
});

export default Autocomplete;
