import styled from 'styled-components';
import { getGridAtSize, getMarginAsPixels, getMediaQuery, getUnitAsPixels, getVerticalSpaceAsPixels, GRID, Size, VerticalSpaceVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { Link as GatsbyLink } from "gatsby"
import { Slider } from '../slider';
import Color from 'color';

export const Container = styled.div`
  ${GRID}
`;

export const ListContent = styled.div`
  grid-column: 1 / span 8;
  margin-bottom: -${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
    margin-bottom: -${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
    display: grid;
    ${getGridAtSize(Size.MEDIUM,12)}
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 4 / span 9;
    margin-bottom: -${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
    ${getGridAtSize(Size.LARGE,9)}
  }

  ${getMediaQuery(Size.XLARGE)}{
    margin-bottom: -${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
    ${getGridAtSize(Size.XLARGE,9)}
  }

  ${getMediaQuery(Size.MAX)}{
    margin-bottom: -${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
    ${getGridAtSize(Size.MAX,9)}
  }
`;

export const SliderContent = styled(Slider)`
  grid-column: 1 / span 8;
  margin-inline-start: ${getMarginAsPixels(Size.SMALL,{multiplier:-1})};
  margin-inline-end: ${getMarginAsPixels(Size.SMALL,{multiplier:-1})};
  padding-inline-start: ${getMarginAsPixels(Size.SMALL)};
  padding-inline-end: ${getMarginAsPixels(Size.SMALL)};

  backface-visibility: hidden;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
    margin-inline-start: ${getMarginAsPixels(Size.MEDIUM,{multiplier:-1})};
    margin-inline-end: ${getMarginAsPixels(Size.MEDIUM,{multiplier:-1})};
    padding-inline-start: ${getMarginAsPixels(Size.MEDIUM)};
    padding-inline-end: ${getMarginAsPixels(Size.MEDIUM)};
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 4 / span 9;
    margin-inline-start: 0;
    margin-inline-end: ${getMarginAsPixels(Size.LARGE,{multiplier:-1})};
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  ${getMediaQuery(Size.XLARGE)}{
    margin-inline-end: ${getMarginAsPixels(Size.XLARGE,{multiplier:-1})};
  }

  ${getMediaQuery(Size.MAX)}{
    margin-inline-end: 0;
  }
`;

export const ButtonContainer = styled.div`
  grid-column: 1 / span 8;
  padding-top: ${getUnitAsPixels({multiplier:4})};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
    padding-top: ${getUnitAsPixels({multiplier:5})};
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 4 / span 9;
    padding-top: ${getUnitAsPixels({multiplier:7})};
  }
`;

export const ListItem = styled.div<{featured:boolean | undefined}>`
  grid-column: 1 / span 8;
  margin-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: ${({featured}) => featured ? `span 12` : `span 4`};
    margin-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: ${({featured}) => featured ? `span 9` : `span 3`};
    margin-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    margin-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.MAX)}{
    margin-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
  }
`;

export const SliderItem = styled.div<{featured?:boolean}>`
  
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  grid-column: 1 / span 8;
  height: 2px;
  background-color: ${({ theme }) => Color(theme.primary.hex).alpha(0.3).toString()};
  margin-top: ${getUnitAsPixels({multiplier:4})};
  display: flex;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
    margin-top: ${getUnitAsPixels({multiplier:5})};
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 4 / span 9;
    margin-top: ${getUnitAsPixels({multiplier:7})};
  }
`;

export const ProgressBarContent = styled.div<{rtl:boolean,index:number,maxIndex:number}>`
  position: absolute;
  height: 100%;
  ${({rtl}) => rtl ? `right` : `left`}: 0;
  width: ${({maxIndex}) => (1 / (maxIndex + 1)) * 100}%;
  background-color: ${({ theme }) => theme.primary.hex};
  transform: translateX(${({rtl,index}) => (index * 100) * (rtl ? -1 : 1)}%);
  transition: transform 0.3s;
`

export const ProgressBarSegment = styled.div<{selected:boolean}>`
  background-color: ${({ theme }) => theme.primary.hex};
  flex: 1;
  opacity: ${({selected}) => selected ? "1" : "0"};
`

export const Btn = styled.button`
  ${getTypography(Type.BUTTON_2)}
  cursor: pointer;
  height: ${getUnitAsPixels({multiplier:7})};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
	color: ${({theme}) => theme.primary.hex};
  border: 1px solid ${({theme}) => Color(theme.primary.hex).alpha(0.2).toString()};
  text-align: center;
  white-space: nowrap;
  transition: border 0.3s;

  &:hover{
    border: 1px solid ${({theme}) => theme.primary.hex};
  }
`;