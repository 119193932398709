import React from 'react';
import { Layout } from '../../../theme/layout';
import { Before, Container, Content, Rule } from './container.styles';

export const BlockContainer = ({layout,children,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter,borderBefore}:{layout:Layout,children:any,extraSpaceBefore?:boolean,extraSpaceAfter?:boolean,noSpaceBefore?:boolean,noSpaceAfter?:boolean,borderBefore?:boolean}) => {
  return (
    <Container extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter}>
      {borderBefore && <Before>
        <Rule />  
      </Before>}
      <Content layout={layout} borderBefore={borderBefore}>
        {children}
      </Content>
    </Container>
  )
};