import { sign } from 'fp-ts/lib/Ordering';
import { useViewportScroll } from 'framer-motion';
import { useRef, useState, useMemo, useEffect } from 'react';

export const useScroll = ({top=0}={top:0}):{top?:number} => {
  const scrollScratch = useRef(0);
  const { scrollY } = useViewportScroll();
  const [_scrollDirection, setScrollDirection] = useState(0);
  const scrollDirection = useMemo(() => _scrollDirection, [_scrollDirection]);
  const [scrollIsTop, setScrollIsTop] = useState(scrollY.get() <= top);

  useEffect(() => {
    const unsubscribe = scrollY.onChange((sy) => {
      const dir = sign(sy - scrollScratch.current);
      if (dir !== 0) {
        setScrollDirection(dir);
      }
      scrollScratch.current = sy;
      setScrollIsTop(sy <= top);
    });
    return () => void unsubscribe();
  }, [scrollY]);

  return { scrollDirection, scrollIsTop };
};

export default useScroll;
