import { useReducer, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

const transitionTime = 400;
const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`;
const smooth = `transform ${transitionTime}ms ease`;
const threshold = 100;

const initialSliderState = {
	offset: 0,
	desired: 0,
	index: 0,
	active: 0
};

function sliderReducer(state, action) {
	switch (action.type) {
		case 'jump':
			if(action.desired >= 0 && action.desired <= action.maxIndex){
				return {
					...state,
					action,
					desired: action.desired,
					active: action.desired,
				};
			}
			else{
				return {
					...state,
					action
				};
			}
		case 'next':
			if(state.index < action.maxIndex){
				return {
					...state,
					action,
					desired: state.index + 1,
					active: state.index + 1,
				};
			}
			else{
				return {
					...state,
					action,
					offset: 0,
				};
			}
		case 'prev':
			if(state.index > 0){
				return {
					...state,
					action,
					desired: state.index - 1,
					active: state.index - 1,
				};
			}
			else{
				return {
					...state,
					action,
					offset: 0,
				};
			}
		case 'done':
			return {
				...state,
				action,
				offset: NaN,
				index: state.desired,
			};
		case 'drag':
			return {
				...state,
				action,
				offset: action.offset,
			};
		default:
			return state;
	}
}

function swiped(e, dispatch, size, maxIndex, dir) {
	
	// const d = dir * -e.deltaX;

	// console.log(d,threshold);

	if (Math.abs(e.deltaX) >= threshold) {
		const d = dir * -e.deltaX;
		dispatch({
			type: dir > 0 ? 'next' : 'prev',
			maxIndex,
		});
	} else {
		dispatch({
			type: 'drag',
			offset: 0,
		});
	}
}



export function useSlider(
	contentRef,
	maxIndex,
	columnWidth,
	gutterWidth,
	rtl,
	onActiveIndexChange = () => {}
) {
	const slideWidth = columnWidth + gutterWidth;
	const [state, dispatch] = useReducer(sliderReducer, initialSliderState);
	const [active,setActive] = useState(0);
	const handlers = {...useSwipeable({
		// onSwipeStart(e) {
		// 	document.body.style.cursor = "dragging";
		// },
		onSwiping(e) {
			if(e.dir === "Left" || e.dir === "Right" && !e.first){
				dispatch({
					type: 'drag',
					offset: e.deltaX * (rtl ? -1 : 1),
				});
			}
			
		},
		onSwipedLeft(e) {
			swiped(e, dispatch, slideWidth, maxIndex, rtl ? -1 : 1);
		},
		onSwipedRight(e) {
			swiped(e, dispatch, slideWidth, maxIndex, rtl ? 1 : -1);
		},
		trackMouse: true,
		trackTouch: true,
		delta: 10,
	})
	};

	useEffect(() => {
		setActive(state.desired);
		const id = setTimeout(() => dispatch({ type: 'done' }), transitionTime);
		return () => clearTimeout(id);
	}, [state.desired]);

	useEffect(() => {
		if(state.index > maxIndex){
			dispatch({ type: 'jump', desired: maxIndex, maxIndex })
		}
	},[maxIndex])

	useEffect(() => {
		if(onActiveIndexChange){
			onActiveIndexChange({index:active,maxIndex})
		}
	},[active])

	const contentStyle = {
		transform: 'translate3d(0,0,0)',
		[rtl ? "right" : "left"]: `-${state.index * slideWidth}px`,
	};

	if (state.desired !== state.index) {
		const pref = Math.sign(state.offset || 0);
		const dir = Math.sign(state.index - state.desired);
		const shift = (slideWidth * (pref || dir));

		console.log(shift);

		contentStyle.transition = smooth;
		// contentStyle.transform = `translate3d(${shift * (rtl ? -1 : 1)}px,0,0)`;
		contentStyle.transform = `translate3d(${shift * (rtl ? -1 : 1)}px,0,0)`;
	}
	else if (!isNaN(state.offset)) {
		if (state.offset !== 0) {
			// console.log(state.offset);
			const offset = Math.min(Math.max(state.offset,state.index === maxIndex ? 0 : -slideWidth),state.index === 0 ? 0 : slideWidth);
			contentStyle.transform = `translate3d(${offset *  (rtl ? -1 : 1)}px, 0, 0)`;
			// const a = state.index + (Math.round(offset / slideWidth) *  (rtl ? -1 : 1));
			const a = state.index + (Math.round(offset / slideWidth) *  (rtl ? -1 : 1));
			console.log(a,active);
			if(a !== active){
        setActive(a);
      }

		} else {
			contentStyle.transition = elastic;
		}
	}

	if(contentRef.current){
		contentRef.current.style.transition = contentStyle.transition || "none";
		contentRef.current.style.transform = contentStyle.transform;
		contentRef.current.style[rtl ? "right" : "left"] = contentStyle[rtl ? "right" : "left"];
		contentRef.current.style.cursor = state.action && state.action.type === "drag" ? "grabbing" : "grab";
	}

	return {index:state.index, setIndex:n => dispatch({ type: 'jump', desired: n, maxIndex }), handlers};
}