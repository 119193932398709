import styled from "styled-components";
import { getGridAtSize, getGutterAsPixels, getMediaQuery, getUnitAsPixels, Size } from "../../../theme/layout";
import { CardLayout } from "./card.types";

export const HeaderContainer = styled.div`
  
`

export const ContentContainer = styled.div`
  
`

export const FooterContainer = styled.div`
  margin-top: ${getUnitAsPixels()};
`

export const FooterInHeaderContainer = styled.div`
  margin-top: ${getUnitAsPixels({multiplier:2})};
`

export const Container = styled.div<{mobileLayout:CardLayout,desktopLayout:CardLayout}>`
  grid-template-columns: repeat(8, [col-start] 1fr);
  column-gap: ${getGutterAsPixels(Size.SMALL)};
  grid-template-rows: max-content;

  ${FooterInHeaderContainer}{
    display: ${({mobileLayout}) => mobileLayout === CardLayout.HORIZONTAL ? `block` : `none`};
  }

  ${FooterContainer}{
    display: ${({mobileLayout}) => mobileLayout === CardLayout.HORIZONTAL ? `none` : `block`};
  }

  ${ContentContainer}{
    grid-column: 1 / span 3; 
    grid-row: 1 / span 2;
  }

  ${HeaderContainer}{
    grid-column: 4 / span 5;
  }

  ${({mobileLayout}) => mobileLayout === CardLayout.HORIZONTAL ? `
    display: grid;
  ` : `
    display: block;
  `}

  ${getMediaQuery(Size.MEDIUM)}{
    ${getGridAtSize(Size.MEDIUM)}
    grid-template-rows: max-content;

    ${FooterInHeaderContainer}{
      display: ${({desktopLayout}) => desktopLayout === CardLayout.HORIZONTAL ? `block` : `none`};
    }
    
    ${FooterContainer}{
      display: ${({desktopLayout}) => desktopLayout === CardLayout.HORIZONTAL ? `none` : `block`};
      grid-column: 1 / span 5;
    }

    ${ContentContainer}{
      grid-column: 7 / span 6; 
      grid-row: 1 / span 2;
    }

    ${HeaderContainer}{
      grid-column: 1 / span 5;
    }
    
    ${({desktopLayout}) => desktopLayout === CardLayout.HORIZONTAL ? `
      display: grid;
    ` : `
      display: block;
    `}
  }

  ${getMediaQuery(Size.LARGE)}{
    ${getGridAtSize(Size.LARGE,9)}

    ${FooterContainer}{
      grid-column: 1 / span 4;
    }

    ${ContentContainer}{
      grid-column: 6 / span 4;
    }

    ${HeaderContainer}{
      grid-column: 1 / span 4;
    }
  }
`;