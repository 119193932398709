import type {
  AutocompleteCollection as AutocompleteCollectionT,
  GetItemProps,
  GetListProps,
} from '@algolia/autocomplete-core';
import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useBrandTheme } from '../../../context/brand';
import { useDictionary } from '../../../context/dictionary';
import { Layout } from '../../../theme/layout';
import { ArticleList } from '../../article/articleList';
import { BlockContainer } from '../../block/container';
import { ShowList } from '../../show/showList';
import type { BaseItem, ContentType } from './autocomplete.component';

type Props = {
  collection: AutocompleteCollectionT<BaseItem>;
  index: number;
  getListProps: GetListProps;
  getItemProps: GetItemProps<BaseItem, MouseEvent>;
};

const AutocompleteCollection = ({ collection, index, getListProps, getItemProps }: Props) => {
  const expand = () => void setExpanded(true);
  const { source, items } = collection;
  const { sourceId } = source;
  const [expanded, setExpanded] = useState(sourceId === 'TV_SHOW' ? true : false);
  if (items.length < 1) return null;

  // const items = expanded ? collectionItems : collectionItems.slice(0, 3);
  // const showMoreButton: boolean = !expanded && collectionItems.length > 3;

  const labelTvShows = useDictionary("labelTvShows");
  const labelBlogArticles = useDictionary("labelBlogArticles");
  const labelTvMoreShows = useDictionary("labelTvMoreShows");
  const labelBlogMoreArticles = useDictionary("labelBlogMoreArticles");

  const themeDark = useBrandTheme("dark");

  switch (sourceId as ContentType) {
    case 'tv_show':
      return (
        <ThemeProvider theme={themeDark}>
          <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={true} extraSpaceAfter={true}>
            <ShowList heading={labelTvShows} items={items} more={{label:labelTvMoreShows,max:3}} />
          </BlockContainer>
        </ThemeProvider>
      )
    case 'blog_article':
      return (
        <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={true} extraSpaceAfter={true}>
          <ArticleList heading={labelBlogArticles} items={items} more={{label:labelBlogMoreArticles,max:3}} />
        </BlockContainer>
      )
    default:
      return null;
  }
};

export default AutocompleteCollection;
