export const isInternal = (url: string) => {
  // check against regex
  return /^\/(?!\/)/.test(url);
};

export const tidyURL = (url: string) => {
  // remove this domain
  const domain = process.env.URL ?? '';
  return url.replace(domain, '');
};

export const getLocalizedPath = ({isLocalized,isSingleCountry,locale}:{locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  const language = !!locale ? locale.split('-')[0] : ``;

  return `${isLocalized && !!locale ? `/${isSingleCountry ? language : locale}` : ``}`
}

export const standardiseInternalURL = (url: string) => {
  return url.replace(/\/?(\?|#|$)/, '/$1').toLowerCase();
};

export const getHomePath = ({isLocalized,isSingleCountry,locale}:{locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}/`;
};

export const getArticlePath = ({isLocalized,isSingleCountry,locale,slug}:{slug?:string,locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}/editorial/article/${slug}/`;
};

export const getBlogVideoPath = ({isLocalized,isSingleCountry,locale,slug}:{slug?:string,locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}/editorial/video/${slug}/`;
};

export const getAuthorPath = ({isLocalized,isSingleCountry,locale,slug}:{slug?:string,locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}/editorial/author/${slug}/`;
};

export const getTopicPath = ({isLocalized,isSingleCountry,locale,slug}:{slug?:string,locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}/editorial/topic/${slug}/`;
};

export const getTagPath = ({isLocalized,isSingleCountry,locale,slug}:{slug?:string,locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}/editorial/tag/${slug}/`;
};

export const getPagePath = ({section,locale,slug,isLocalized,isSingleCountry}:{section?:string,slug?:string,locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}${section ? section : ''}/${slug}/`;
};

export const getTvVideoPath = ({isLocalized,isSingleCountry,locale,slug}:{slug?:string,locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}/tv/video/${slug}/`;
};

export const getTvShowPath = ({isLocalized,isSingleCountry,locale,slug}:{slug?:string,locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}/tv/show/${slug}/`;
};

export const getTvHomePath = ({isLocalized,isSingleCountry,locale}:{locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}/tv/`;
};

export const getTvTopicPath = ({isLocalized,isSingleCountry,locale,slug}:{slug?:string,locale?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  return `${getLocalizedPath({isLocalized,isSingleCountry,locale})}/tv/topic/${slug}/`;
};

export const getInternalURL = ({pageType,section,locale,slug,isLocalized,isSingleCountry}:{pageType:string,section?:string,locale?:string,slug?:string,isLocalized:boolean,isSingleCountry:boolean}) => {
  switch(pageType){
    case 'page_home':
      return getHomePath({isLocalized,isSingleCountry,locale})
    case 'blog_article':
      return getArticlePath({isLocalized,isSingleCountry,locale,slug})
    case 'blog_video':
      return getBlogVideoPath({isLocalized,isSingleCountry,locale,slug})
    case 'blog_author':
      return getAuthorPath({isLocalized,isSingleCountry,locale,slug})
    case 'blog_topic':
      return getTopicPath({isLocalized,isSingleCountry,locale,slug})
    case 'blog_tag':
      return getTagPath({isLocalized,isSingleCountry,locale,slug})
    case 'page_block':
      return getPagePath({isLocalized,isSingleCountry,section,locale,slug})
    case 'tv_video':
      return getTvVideoPath({isLocalized,isSingleCountry,locale,slug})
    case 'tv_show':
      return getTvShowPath({isLocalized,isSingleCountry,locale,slug})
    case 'tv_home':
      return getTvHomePath({isLocalized,isSingleCountry,locale})
    case 'tv_topic':
      return getTvTopicPath({isLocalized,isSingleCountry,locale,slug})
  }

  return '/';
}

export const removeTrailingSlash = (path) => {
  return path.length > 1 ? path.replace(/\/$/, "") : path;
}

export const getEmailURL = (address: string) => {
  return `mailto:${address}`;
};

export const getPhoneURL = (phone: string) => {
  return `tel:${phone.replace(/\s+/g, '')}`;
};