import React, {Fragment} from 'react';

export const plainTextToHTML = (text?:string) => {
  if(text){
    return (
      text
      .split('\n\n').map((paragraph:string, pi:number) => (
        <p key={pi}>
          {paragraph.split('\n').map((line:string, li:number, array:Array<string>) => (
            <Fragment key={li}>
              {line}
              {li < array.length - 1 && <br />}
            </Fragment>
          ))}
        </p>
      ))
    )
  }

  return null;
}