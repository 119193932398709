import { useRef, useEffect } from 'react';
import { document } from 'browser-monads';

export const usePortal = () => {
  const rootRef = useRef(document.createElement('div'));

  useEffect(() => {
    const parentElem = document.getElementById('portal');
    rootRef.current.setAttribute('role', 'dialog');
    rootRef.current.setAttribute('aria-modal', 'true');
    rootRef.current.style.position = 'fixed';
    rootRef.current.style.top = '0';
    rootRef.current.style.left = '0';
    rootRef.current.style.right = '0';
    rootRef.current.style.bottom = '0';
    rootRef.current.style.zIndex = '100';
    parentElem.appendChild(rootRef.current);
    return () => {
      rootRef.current.remove();
    };
  }, []);

  return rootRef.current;
};
