export const secondsToFormattedString = (s:number) => {
  const minutes = Math.floor(s / 60);
  const seconds = s - (minutes * 60);
  return `${minutes > 0 ? `${minutes}` : `0`}:${seconds < 10 ? `0${seconds}` : seconds}`
}

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export const daysAfterTimestamp = (timestamp:number) => Math.floor((Date.now() - timestamp) / DAY)

export const daysAfterDate = (dateString:string) => daysAfterTimestamp(new Date(dateString).getTime())