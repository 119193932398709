import { globalHistory } from '@reach/router';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useBrandTheme } from '../../../context/brand';
import { RawPortal } from '../../layout/portal/portal.component';
import Autocomplete from './autocomplete.component';
import { Container } from './search.styles';

const presenceProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  // transition: {
  //   delay: 1,
  // },
};

type Props = {
  onClose?: () => void;
  isOpen: boolean;
};


export const Search = ({isOpen,onClose = () => {}}:Props) => {
  const themeLight = useBrandTheme("light");
  
  useEffect(() => {
    if(isOpen){
      document.body.style.overflow = 'hidden';
    }
    else{
      document.body.style.overflow = 'unset';
    }

    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') onClose()
    })
  },[isOpen]);
  
  return (
    <AnimatePresence>
      {isOpen ? (
        <RawPortal key="rawPortal">
          <ThemeProvider theme={themeLight}>
            <Container
              key="portalContainer"
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <Autocomplete />
            </Container>
          </ThemeProvider>
        </RawPortal>
      ) : null}
    </AnimatePresence>
  )
}


