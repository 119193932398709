import { MotionProps } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import { globalHistory } from '@reach/router'
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  NavigationLinkSocialFragment,
  DatoCmsBlogTopic,
  DatoCmsLinkExternal,
  DatoCmsLinkInternal,
  NavigationLinkExternalFragment,
  NavigationLinkInternalFragment,
  NavigationLinkTopicFragment,
  PageQuery,
} from '../../../common/types/graphql';
import { useIsRTL, useLocale } from '../../../context/locale';
import { useBrandImage, useBrandTheme } from '../../../context/brand';
import { MenuLocale } from './locale.component';
import {
  Container,
  Content,
  LinkList,
  LinkListItem,
  LinkListLink,
  LinkListLinkSmall,
  Overlay,
  Strip,
  SubNav,
  SubNavItem,
  Tag,
} from './menu.styles';
import { useDictionary } from '../../../context/dictionary';

type Props = MotionProps & {
  onClose?: () => void;
  isOpen: boolean;
  maxWidth?: number;
};


export const Menu = ({ isOpen, onClose = () => {}, maxWidth = 617 }: Props) => {
  const locale = useLocale();

  const data = useStaticQuery(graphql`
    query Menu {
      navigation:allDatoCmsNavigation {
        nodes {
          locale
          topics {
            ...NavigationLinkTopic
          }
          tags {
            ...NavigationLinkTag
          }
          pages {
            ...NavigationLinkInternal
            ...NavigationLinkExternal
          }
          social {
            ...NavigationLinkSocial
          }
        }
      }
    }
  `);

  const labelBlogTopics = useDictionary("labelBlogTopics");
  const labelBlogTags = useDictionary("labelBlogTags");

  const navigation = get(data, 'navigation.nodes', []).find((n) => n.locale === locale);
  const topics = get(navigation, 'topics');
  const tags = get(navigation, 'tags');
  const pages = get(navigation, 'pages');
  const social = get(navigation, 'social');

  const strip = useBrandImage("menu");
  
  const ref = useRef<HTMLDivElement | null>(null);
  const rtl = useIsRTL();

  const [contentType, setContentType] = useState("topics");

  // scroll back to top on close
  useEffect(() => {
    let timer: any = null;
    if (!isOpen && ref?.current?.scrollTop) {
      timer = setTimeout(() => {
        ref.current!.scrollTop = 0;
      }, 1000);
    }
    return () => void (timer !== null ? void clearTimeout(timer) : null);
  }, [isOpen]);

  const theme = useBrandTheme("dark");

  useEffect(() => {
    if(isOpen){
      document.body.style.overflow = 'hidden';
    }
    else{
      document.body.style.overflow = 'unset';
    }

    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') onClose()
    })
  },[isOpen]);

  return (
    <ThemeProvider theme={theme}>
      <AnimatePresence>
        {isOpen ? (
          <Overlay initial={{ opacity: 0 }} animate={{ opacity: 0.3 }} exit={{ opacity: 0 }} onClick={onClose} />
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {isOpen ? (
          <Container $maxWidth={maxWidth} $rtl={rtl} initial={{ x: rtl ? -maxWidth : maxWidth }} transition={{ease: 'easeInOut',duration: 0.4}} animate={{ x: 0 }} exit={{ x: rtl ? -maxWidth : maxWidth }}>
            <Content>
              <SubNav>
                <SubNavItem selected={contentType === "topics"} onClick={() => setContentType("topics")}>{labelBlogTopics}</SubNavItem>
                <SubNavItem selected={contentType === "tags"} onClick={() => setContentType("tags")}>{labelBlogTags}</SubNavItem>
              </SubNav>
              {contentType === "topics" && <LinkList>
                {topics.map((link, i) => (
                  <LinkListItem key={i}>
                    {link.tag && <Tag>{link.tag}</Tag>}
                    <LinkListLink link={link}>{link.page.name}</LinkListLink>
                  </LinkListItem>
                ))}
              </LinkList>}
              {contentType === "tags" && <LinkList>
                {tags.map((link, i) => (
                  <LinkListItem key={i}>
                    {link.tag && <Tag>{link.tag}</Tag>}
                    <LinkListLink link={link}>{link.page.name}</LinkListLink>
                  </LinkListItem>
                ))}
              </LinkList>}
              <LinkList>
                {pages.map((link, i) => (
                  <LinkListItem key={i}>
                    {link.tag && <Tag>{link.tag}</Tag>}
                    <LinkListLink link={link}>{link.label}</LinkListLink>
                  </LinkListItem>
                ))}
              </LinkList>
              <LinkList>
                {social.map((link, i) => (
                  <LinkListItem key={i}>
                    <LinkListLinkSmall link={link}>{link.label}</LinkListLinkSmall>
                  </LinkListItem>
                ))}
              </LinkList>
              <MenuLocale />
            </Content>
            <Strip src={strip.url} />
          </Container>
        ) : null}
      </AnimatePresence>
    </ThemeProvider>
  );
}