import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from './portal.hooks';
import { document } from 'browser-monads';

interface PortalProps {
  children: ReactNode;
}

export function Portal({ children }: PortalProps) {
  const target = usePortal();

  return createPortal(children, target);
}

export const RawPortal = ({ children }: PortalProps) => {
  const target = document.getElementById('portal');
  return createPortal(children, target);
};
