import styled from 'styled-components';
import { ThemeType } from '../../../theme/color';
import { CONTAINER, getLayout, getMediaQuery, getSpacerAsPixels, getVerticalSpaceAsPixels, Layout, Size, VerticalSpaceVariant } from '../../../theme/layout';

export const Container = styled.section<{theme:ThemeType,extraSpaceBefore?:boolean,extraSpaceAfter?:boolean,noSpaceBefore?:boolean,noSpaceAfter?:boolean}>`
  ${CONTAINER}
  position: relative;
  background-color: ${({theme}) => theme.background.hex};
	color: ${({theme}) => theme.primary.hex};
  padding-top: ${({noSpaceBefore,extraSpaceBefore}) => noSpaceBefore ? `0` : getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE,{multiplier:extraSpaceBefore ? 1 : 0.5})};
  padding-bottom: ${({noSpaceAfter,extraSpaceAfter}) => noSpaceAfter ? `0` : getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE,{multiplier:extraSpaceAfter ? 1 : 0.5})};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top:${({noSpaceBefore,extraSpaceBefore}) => noSpaceBefore ? `0` : getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE,{multiplier:extraSpaceBefore ? 1 : 0.5})};
    padding-bottom: ${({noSpaceAfter,extraSpaceAfter}) => noSpaceAfter ? `0` : getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE,{multiplier:extraSpaceAfter ? 1 : 0.5})};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${({noSpaceBefore,extraSpaceBefore}) => noSpaceBefore ? `0` : getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE,{multiplier:extraSpaceBefore ? 1 : 0.5})};
    padding-bottom: ${({noSpaceAfter,extraSpaceAfter}) => noSpaceAfter ? `0` : getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE,{multiplier:extraSpaceAfter ? 1 : 0.5})};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${({noSpaceBefore,extraSpaceBefore}) => noSpaceBefore ? `0` : getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE,{multiplier:extraSpaceBefore ? 1 : 0.5})};
    padding-bottom: ${({noSpaceAfter,extraSpaceAfter}) => noSpaceAfter ? `0` : getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE,{multiplier:extraSpaceAfter ? 1 : 0.5})};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${({noSpaceBefore,extraSpaceBefore}) => noSpaceBefore ? `0` : getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE,{multiplier:extraSpaceBefore ? 1 : 0.5})};
    padding-bottom: ${({noSpaceAfter,extraSpaceAfter}) => noSpaceAfter ? `0` : getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE,{multiplier:extraSpaceAfter ? 1 : 0.5})};
  }
`;

export const Content = styled.div<{layout:Layout,borderBefore?:boolean}>`
  ${({layout}) => getLayout(layout)}
  position: relative;
`;

export const Before = styled.div`
  ${getLayout(Layout.BLOCK)};

  ${getMediaQuery(Size.LARGE)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
  }
`;

export const Rule = styled.hr`
  border-top: 1px solid ${({theme}) => theme.primary.hex};
  padding: 0;
  margin: 0;
  display: none;

  ${getMediaQuery(Size.LARGE)}{
    display: block;
  }
`