import styled from 'styled-components';
import { getMarginAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, Size, VerticalSpaceVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  padding-left: ${getMarginAsPixels(Size.SMALL,{multiplier:2})};
  padding-right: ${getMarginAsPixels(Size.SMALL,{multiplier:2})};


  ${getMediaQuery(Size.MEDIUM)}{
    padding-left: ${getMarginAsPixels(Size.MEDIUM,{multiplier:2})};
    padding-right: ${getMarginAsPixels(Size.MEDIUM,{multiplier:2})};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-left: ${getMarginAsPixels(Size.LARGE)};
    padding-right: ${getMarginAsPixels(Size.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-left: ${getMarginAsPixels(Size.XLARGE)};
    padding-right: ${getMarginAsPixels(Size.XLARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-left: ${getMarginAsPixels(Size.MAX)};
    padding-right: ${getMarginAsPixels(Size.MAX)};
  }
`;

export const Inner = styled.div`
  background-color: ${({theme}) => theme.background.hex};
  color: ${({theme}) => theme.primary.hex};
  flex: 1;
  max-width: 1000px;
  position: relative;
  
  ${getMediaQuery(Size.LARGE)}{
    display: flex;
    min-height: 360px;
  }
`

export const Heading = styled.h2`
  ${getTypography(Type.SECTION_HEADING)}
  margin-bottom: ${getUnitAsPixels({multiplier:1})};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getUnitAsPixels({multiplier:2})};
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_2)}
  margin-bottom: ${getUnitAsPixels({multiplier:2})};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getUnitAsPixels({multiplier:3})};
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

export const ContentContainer = styled.div`
  flex: 1 0 50%;
  padding: ${getUnitAsPixels({multiplier:2})};

  ${getMediaQuery(Size.LARGE)}{
    padding: ${getSpacerAsPixels()};
  }
`;

export const IllustrationContainer = styled.div`
  position: relative;
  display: none;

  ${getMediaQuery(Size.LARGE)}{
    display: block;
    flex: 1 0 50%;
  }
`

export const Illustration = styled.img`
  display: block;
  width: 100%;
  height: ${getSpacerAsPixels({multiplier:4})};
  object-fit: cover;
  object-position: 50% 0;
  
  ${getMediaQuery(Size.LARGE)}{
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    object-position: 0 50%;
  }
`

export const CloseButton = styled.svg<{ isRTL: boolean }>`
  stroke: ${({theme}) => theme.primary.hex};
  position: absolute;
  top: ${getUnitAsPixels({multiplier:3})};
  cursor: pointer;
  ${({isRTL}) => isRTL ? `left` : `right`}: ${getUnitAsPixels({multiplier:3})};
`