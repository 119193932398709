import React, { useState } from "react"

import { CardLayout } from "../../core/card/card.types";
import { ShowCard } from "../showCard";
import { ShowListProps } from "./showList.types";
import { CardContainer, CardContainerListContent, CardContainerListItem } from "../../core/cardContainer";

export const ShowList = ({ items, link, heading, subheading, more }:ShowListProps) => {
  const [expanded, setExpanded] = useState(false);
  const handleButtonClick = () => {
    setExpanded(true);
  }
  const button = more && items.length > more.max && !expanded ? more.label : undefined;
  const limit = more ? expanded ? items.length : more.max : items.length; 

  return (
    <CardContainer heading={heading} subheading={subheading} link={link} button={button} onButtonClick={handleButtonClick}>
      <CardContainerListContent>
        {items.slice(0,limit).map((item, index) => <CardContainerListItem key={item.id}><ShowCard show={item} mobileLayout={CardLayout.HORIZONTAL} desktopLayout={CardLayout.VERTICAL} /></CardContainerListItem>)}
      </CardContainerListContent>
    </CardContainer>
  )
}