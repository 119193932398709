import { useEffect } from 'react';
import { window } from 'browser-monads';

export const useEscButton = (callback: () => void): void => {
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        callback();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);
};
