import { ReactChild } from "react";
import { LinkExternal, LinkInternal } from "../../../common/types";
import { ArticleCardProps } from "../articleCard/articleCard.types";

export interface ArticleListProps {
  heading: string;
  subheading?: string;
  items:Array<ArticleCardProps>;
  link?: LinkInternal | LinkExternal;
  variant?: ArticleListVariant;
  more?:{
    max: number;
    label: string;
  }
}

export enum ArticleListVariant {
  STACKED = "STACKED",
  FEATURE = "FEATURE",
  DEFAULT = "DEFAULT",
}