export interface MailchimpProps{
  endpoint: string;
  labelButton: string;
  placeholderEmail: string;
  messageSuccess: string;
  messageFail: string;
  onSubmitted?: Function;
}

export enum FormState {
  SUCCESS = 'Success',
  FAIL = 'Fail',
  PROCESSING = 'Processing',
  DEFAULT = 'Default'
}