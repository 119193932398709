import React, { useState } from "react"

import { ArticleCard } from "../articleCard/articleCard.component";
import { ArticleListProps, ArticleListVariant } from "./articleList.types";
import { CardLayout } from "../../core/card/card.types";
import { CardContainer, CardContainerListContent, CardContainerListItem } from "../../core/cardContainer";

export const ArticleList = ({ heading, subheading, items, variant, link, more }:ArticleListProps) => {
  const [expanded, setExpanded] = useState(false);
  const handleButtonClick = () => {
    setExpanded(true);
  }
  const button = more && items.length > more.max && !expanded ? more.label : undefined;
  const limit = more ? expanded ? items.length : more.max : items.length; 

  return (
    <CardContainer heading={heading} subheading={subheading} link={link} button={button} onButtonClick={handleButtonClick}>
      <CardContainerListContent>
        {items.slice(0,limit).map((item, index) => {
          const featured = variant === ArticleListVariant.STACKED || (variant === ArticleListVariant.FEATURE && index === 0 && items.length > 3);
          return (
            <CardContainerListItem key={index} featured={featured}><ArticleCard index={index} featured={featured} article={item} mobileLayout={variant === ArticleListVariant.FEATURE && index !== 0 ? CardLayout.HORIZONTAL : CardLayout.VERTICAL} desktopLayout={variant === ArticleListVariant.STACKED || (variant === ArticleListVariant.FEATURE && index === 0 && items.length > 3) ? CardLayout.HORIZONTAL : CardLayout.VERTICAL} /></CardContainerListItem>
          )
        })}
      </CardContainerListContent>
    </CardContainer>
  )
}