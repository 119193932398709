import get from 'lodash/get';
import React from 'react'

import { DefaultContainer, DefaultVideo, PortraitContainer, PortraitVideo, Video, Wrapper } from './responsiveVideo.styles'

const getVideo = (source) => {
	const video = get(source,"video");

	if(video){
		return {
			...video,
			width: get(source,"width"),
			height: get(source,"height"),
		}
	}
	
	return null;
}

export const ResponsiveVideo = ({defaultSource,portraitSource,className,onClick}) => {
	
  const defaultVideo = getVideo(defaultSource);
  const portraitVideo = getVideo(portraitSource);

  return (
		<Wrapper className={className}>
			<DefaultContainer aspectRatio={defaultVideo.height / defaultVideo.width} hideOnPortrait={!!portraitVideo}>
				<Video onClick={onClick} autoPlay muted playsInline loop disablePictureInPicture>
					<source src={defaultVideo.streamingUrl} type="application/x-mpegURL" />
					<source src={defaultVideo.mp4Url} type="video/mp4" />
				</Video>
			</DefaultContainer>
			{portraitVideo && (
				<PortraitContainer aspectRatio={portraitVideo.height / portraitVideo.width}>
					<Video onClick={onClick} autoPlay muted playsInline loop disablePictureInPicture>
						<source src={portraitVideo.streamingUrl} type="application/x-mpegURL" />
						<source src={portraitVideo.mp4Url} type="video/mp4" />
					</Video>
				</PortraitContainer>
			)}
		</Wrapper>
	)
}