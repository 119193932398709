import { AnimatePresence, motion } from 'framer-motion';
import React, { Fragment } from 'react';

import SvgCloseBase from '../../svg/close/close.component';
import SvgSearchBase from '../../svg/search/search.component';

const SvgSearch = motion(SvgSearchBase);
const SvgClose = motion(SvgCloseBase);

export const SearchIcon = ({ isOpen }: {isOpen:boolean}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen ? (
        <SvgClose key="close" />
      ) : (
        <SvgSearch key="search" />
      )}
    </AnimatePresence>
  )
}