import styled from "styled-components";
import { motion } from 'framer-motion';
import { getTypography, Type } from "../../../theme/typography";
import { getUnitAsPixels } from "../../../theme/layout";
import Color from "color";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Arrow = styled.svg`
  stroke: ${({ theme }) => theme.primary.hex};
  transition: transform 0.3s;
`

export const CurrentLocale = styled.button<{isOpen:boolean}>`
  ${getTypography(Type.LABEL_2)}
  color: ${({ theme }) => theme.primary.hex};
  display: flex;
  padding: ${getUnitAsPixels({multiplier:1})} 0;
  cursor: pointer;

  ${Arrow}{
    ${({ isOpen }) => isOpen ? `transform: scale(1,-1);` : ``};
  }
`

export const LocaleList = styled(motion.ul)`
  border-top: 1px solid ${({ theme }) => Color(theme.primary.hex).alpha(0.2).toString()};
  padding: ${getUnitAsPixels({multiplier:0.5})} 0;
`

export const LocaleItem = styled.li`
  
`



export const LocaleLink = styled.a`
  ${getTypography(Type.LABEL_2)}
  color: ${({ theme }) => theme.primary.hex};
  padding: ${getUnitAsPixels({multiplier:0.5})} 0;
  display: flex;
  transition: color 0.3s;

  &:hover{
    color: ${({ theme }) => theme.secondary.hex};
  }
`

export const Flag = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-inline-end: ${getUnitAsPixels()};
`