import React, { Fragment, useState } from "react";
import { useDictionary } from "../../../context/dictionary";
import { ResponsiveImage } from "../../core/responsiveMedia";
import { Caption, CaptionLabel, CaptionValue, Heading, Meta, Published, ThumbnailContainer, Topic, Author, Badge, Thumbnail, HeadingInner } from "./articleCard.styles";
import { Size } from "../../../theme/layout";
import { ArticleCardProps } from "./articleCard.types";
import { Card } from "../../core/card";
import { useIsRTL } from "../../../context/locale";
import { CardLayout } from "../../core/card/card.types";

export const ArticleCard = ({ index, article, mobileLayout, desktopLayout, featured, selected, collection }:ArticleCardProps) => {
  const labelBlogAuthored = useDictionary("labelBlogAuthored");
  const labelBlogPart = useDictionary("labelBlogPart");
  const isRTL = useIsRTL();

  const sizes = {
    [Size.SMALL]: {span: mobileLayout === CardLayout.VERTICAL ? 6 : 8},
    [Size.MEDIUM]: {span: mobileLayout === CardLayout.VERTICAL ? 4 : 8},
    [Size.LARGE]: {span: desktopLayout === CardLayout.VERTICAL ? 3 : 6},
    [Size.XLARGE]: {span: desktopLayout === CardLayout.VERTICAL ? 3 : 6},
    [Size.MAX]: {span: desktopLayout === CardLayout.VERTICAL ? 3 : 6},
  };
  
  return (
    <Card mobileLayout={mobileLayout} desktopLayout={desktopLayout}
      header={
        <>
          <Meta>
            {article.topics && article.topics.length > 0 && <Topic page={article.topics[0]} label={article.topics[0].name} />}
            <Published>{article.meta.firstPublishedAtFormatted}</Published>
          </Meta>
          <Heading page={article} featured={featured} mobileLayout={mobileLayout} desktopLayout={desktopLayout}>
            <HeadingInner>{article.title}</HeadingInner>
          </Heading>
        </>
      }
      footer={article.authors?.length > 0 ?
        <Caption>
          <CaptionLabel>{labelBlogAuthored}</CaptionLabel>
          <CaptionValue>{article.authors.map((author,index) => (<Fragment key={index}><Author page={author} label={author.name} />{index < article.authors.length - 1 ? `, ` : ``}</Fragment>))}</CaptionValue>
        </Caption>
      : null}
    >
      {article.thumbnail && 
        <ThumbnailContainer page={article}>
          <Thumbnail defaultSource={article.thumbnail} sizes={sizes} />
          {collection && index !== undefined && <Badge rtl={isRTL} selected={selected}>{`${labelBlogPart} ${index + 1}`}</Badge>}
        </ThumbnailContainer>
      }
    </Card>
  )
}