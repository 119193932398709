import get from 'lodash/get';
import React from 'react';
import { LinkInternal } from './linkInternal.component';
import { LinkExternal } from './linkExternal.component';
import { LinkProps } from './link.types';
import { LinkEmail } from './linkEmail';

export const Link = ({link,className,children,icon}:LinkProps) => {
  const label = get(link,"label");
  const page = get(link,"page");
  const url = get(link,"url");
  const email = get(link,"email");

  if(email){
    return <LinkEmail label={label} icon={icon} email={email} className={className}>{children}</LinkEmail>
  }
  else if(page){
    return <LinkInternal label={label} icon={icon} page={page} className={className}>{children}</LinkInternal>
  }
  else if(url){
    return <LinkExternal label={label} icon={icon} url={url} className={className}>{children}</LinkExternal>
  }

  return null
};