import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import React from 'react';
import { plainTextToHTML } from '../../../common/text';
import { DatoCmsNavigationLinkExternal } from '../../../common/types/graphql';
import { useBrandImage } from '../../../context/brand';
import { useDictionary } from '../../../context/dictionary';
import { useLocale } from '../../../context/locale';
import { Link, LinkInternal } from '../../core/link';
import { Container, Content, Legal, LinkList, LinkListItem, LinkListLink, Strip } from './footer.styles';

const NavigationGroupLabel = ({ label, page }) => {
  if (page) {
    return <LinkInternal label={label} page={page} />;
  }

  return <span>{label}</span>;
};

export const NavigationGroup = ({ data }: { data: any }) => {
  return (
    <>
      <NavigationGroupLabel label={data.label} page={data.page} />
      <ul>
        {data.links.map((item,index) => (
          <li key={index}>
            <NavigationItem data={item} />
          </li>
        ))}
      </ul>
    </>
  );
};

export const NavigationItem = ({ data }: { data: DatoCmsNavigationLinkExternal }) => {
  switch (data.link.model.apiKey) {
    case 'link_group':
      return <NavigationGroup data={data} />;
    case 'link_external':
    case 'link_internal':
      return <Link link={data.link} />;
  }

  return null;
};

export const Footer = () => {
  const locale = useLocale();

  const data = useStaticQuery(graphql`
    query Footer {
      navigation:allDatoCmsNavigation {
        nodes {
          locale
          social {
            ...NavigationLinkSocial
          }
        }
      }
    }
  `);

  const navigation = get(data, 'navigation.nodes', []).find((n) => n.locale === locale);
  const social = get(navigation, 'social');
  const strip = useBrandImage("footer");

  const legal = useDictionary("textLegal");
  
  return (
    <Container>
      <Content>
        <LinkList>
          {social.map((link, i) => (
            <LinkListItem key={i}>
              <LinkListLink link={link}>{link.label}</LinkListLink>
            </LinkListItem>
          ))}
        </LinkList>
        {legal && <Legal>{plainTextToHTML(legal)}</Legal>}
      </Content>
      <Strip src={strip.url} />
    </Container>
  );
};
