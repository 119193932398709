import React from 'react';
import { useDictionary } from '../../../context/dictionary';
import { Container, Content, ExpandableButton, Heading, Subheading } from './header.styles';

const Expandable = ({expanded,onExpandClick = () => {}}) => {

  const labelExpand = useDictionary("labelExpand");
  const labelContract = useDictionary("labelContract");

  return (
    <ExpandableButton expanded={expanded} onClick={onExpandClick}>{expanded ? labelContract : labelExpand}</ExpandableButton>
  )
}

export const BlockHeader = ({heading,heading2,subheading,expandable,expanded,onExpandClick = () => {}}:{heading:string,heading2?:string,subheading?:string,expandable?:boolean,expanded?:boolean,onExpandClick?:Function}) => {
  return (
    <Container>
      <Content>
        <Heading light={!!heading2}>{heading}</Heading>
        {heading2 && <Heading>{heading2}</Heading>}
        {subheading && <Subheading>{subheading}</Subheading>}
        {expandable && <Expandable expanded={expanded} onExpandClick={onExpandClick} />}
      </Content>
    </Container>
  )
};