import { MouseEventHandler, ReactNode } from "react";

export interface CardProps {
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  mobileLayout: CardLayout;
  desktopLayout: CardLayout;
}

export enum CardLayout {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}