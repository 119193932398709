import algoliasearch from 'algoliasearch';
import { SearchClient } from 'algoliasearch/lite';

let searchClient: SearchClient | null = null;

const appId = process.env.GATSBY_ALGOLIA_APP_ID ?? process.env.STORYBOOK_ALGOLIA_APP_ID;

const searchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY ?? process.env.STORYBOOK_ALGOLIA_APP_ID;

const getSearchClient = () => {
  if (appId == null || searchKey == null)
    throw new Error('Null appId or searchKey in src/services/algolia/searchClient');
  if (searchClient == null) {
    searchClient = algoliasearch(appId, searchKey);
  }
  return searchClient;
};

export default getSearchClient;
