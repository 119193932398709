import Color from 'color';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from 'styled-components';
import { DatoCmsBrand, DatoCmsNavigation, PageQuery, SitePageContext } from '../../../common/types/graphql';
import { DictionaryProvider } from '../../../context/dictionary';
import { LocaleProvider } from '../../../context/locale';
import { SectionProvider } from '../../../context/section';
import { BrandProvider } from '../../../context/brand';
import '../../../theme/fonts/stylesheet.css';
import { ErrorMessage } from '../../feedback/errorMessage';
import { Footer } from '../footer';
import { Header } from '../header';
import { Container, GlobalStyle } from './page.styles';
import { Popup } from '../../feedback/popup';

const Page = ({ children, pageContext }: PageProps<{}, SitePageContext>) => {
  const data = useStaticQuery<PageQuery>(graphql`
    query Page {
      brand:allDatoCmsBrand {
        nodes {
          locale
          imageIcon{
            url(imgixParams: { crop: "focalpoint", fit: "crop", fm: "png", w:"32", h:"32" })
          }
          imageLogo{
            url
          }
          imageLockup{
            url
          }
          imageFooter{
            url
          }
          imageMenu{
            url
          }
          themeDark {
            ...Theme
          }
          themeLight {
            ...Theme
          }
          themeArticleCover {
            ...Theme
          }
          themeArticleRelated {
            ...Theme
          }
          themeError {
            ...Theme
          }
          colorCommonDark {
            hex
          }
          colorCommonLight {
            hex
          }
          colorCommonPrimary {
            hex
          }
          colorCommonSecondary {
            hex
          }
          colorLogoBackground {
            hex
          }
          colorLogoForeground {
            hex
          }
          colorBarBackground {
            hex
          }
          colorBarForeground {
            hex
          }
          colorFooterBackground {
            hex
          }
          colorFooterForeground {
            hex
          }
        }
      }
      allDatoCmsDictionary {
        nodes {
          locale
          labelBlogAuthored
          labelTvVideos
          labelTvShows
          labelTvUpdated
          textLegal
          labelBlogTags
          labelBlogTopics
          labelBlogMoreTopics
          labelTvLatestShows
          labelTvMoreShows
          labelTvMoreVideos
          labelBlogMoreArticles
          labelBlogRelatedArticles
          labelBlogArticles
          textSearchResultsMatch
          textSearchNoResults
          placeholderSearch
          labelBlogTagged
          labelBlogLinks
          labelBlogPart
          heading404
          text404
          labelExpand
          labelContract
          headingError
          labelTvVideosCount
          labelTvShowsCount
          labelBlogArticlesCount
          textBlogArticleLegal
        }
      }
      allDatoCmsNavigation {
        nodes {
          locale
          topics {
            ...NavigationLinkTopic
          }
        }
      }
      allDatoCmsPopup {
        nodes {
          id
          originalId
          locale
          heading
          text
          delay
          illustration {
            url
          }
          mailchimp {
            ...Mailchimp
          }
          theme {
            ...Theme
          }
        }
      }
    }
  `);

  const locale = pageContext.locale;
  const brand = get(data, 'brand.nodes', []).find((n:DatoCmsBrand) => n.locale === locale);
  const dictionary = get(data, 'allDatoCmsDictionary.nodes', []).find((n) => n.locale === locale);
  const popups = get(data, 'allDatoCmsPopup.nodes', []).filter((n) => n.locale === locale);
  const navigation = get(data, 'allDatoCmsNavigation.nodes', []).find((n) => n.locale === locale);

  const colors = {
    logo:{
      background: brand.colorLogoBackground,
      foreground: brand.colorLogoForeground,
    },
    bar:{
      background: brand.colorBarBackground,
      foreground: brand.colorBarForeground,
    },
    footer:{
      background: brand.colorFooterBackground,
      foreground: brand.colorFooterForeground,
    },
    common:{
      dark: brand.colorCommonDark,
      light: brand.colorCommonLight,
      primary: brand.colorCommonPrimary,
      secondary: brand.colorCommonSecondary,
    }
  }

  const images = {
    logo: brand.imageLogo,
    icon: brand.imageIcon,
    lockup: brand.imageLockup,
    menu: brand.imageMenu,
    footer: brand.imageFooter
  }

  const themes = {
    dark: brand.themeDark,
    light: brand.themeLight,
    articleCover: brand.themeArticleCover,
    articleRelated: brand.themeArticleRelated,
    error: brand.themeError
  }

  const pageTheme = pageContext.theme || (pageContext.pageType === "error" ? brand.themeError : pageContext.pageType.indexOf("tv") === 0 ? themes.dark : themes.light);
  const themeHeader = pageContext.theme || (pageContext.pageType === "error" ? brand.themeError : pageContext.pageType === "blog_article" ? themes.articleCover : pageContext.pageType.indexOf("tv") === 0 ? themes.dark : themes.light);

  let desktopForeground = colors.common.dark;
  if(pageContext.theme){
    desktopForeground = pageContext.theme.primary;
  }
  else{
    if(pageContext.pageType === "blog_article"){
      desktopForeground = themes.articleCover.primary;
    }
    else if(pageContext.pageType === "page_home"){
      desktopForeground = colors.common.light;
    }
    else if(pageTheme.name === themes.dark.name){
      desktopForeground = colors.common.light;
    }
  }
  
  let mobileForeground = colors.common.dark;
  if(pageContext.theme){
    mobileForeground = pageContext.theme.primary;
  }
  else{
    if(pageContext.pageType === "blog_article"){
      mobileForeground = themes.articleCover.primary;
    }
    else if(pageContext.pageType === "page_home"){
      mobileForeground = colors.common.light;
    }
    else if(pageContext.pageType === "tv_home" || pageContext.pageType === "tv_show"){
      mobileForeground = pageContext.coverVariant === "Light" ? colors.common.light : colors.common.dark;
    }
    else if(pageTheme.name === themes.dark.name){
      mobileForeground = colors.common.light;
    }
  }

  let monochromeLogo = false;
  if(pageContext.theme || pageContext.pageType === "blog_article" || Color(colors.logo.background.hex).contrast(Color(pageTheme.background.hex)) <= 1){
    monochromeLogo = true;
  }

  const ErrorPage = ({ error }: { error: Error }) => (
    <Container>
      <ErrorMessage heading={dictionary.headingError} text={error.message} />
    </Container>
  );

  return (
    <BrandProvider themes={themes} colors={colors} images={images}>
      <ThemeProvider theme={{ ...pageTheme, colors, images }}>
        <LocaleProvider {...pageContext}>
          <DictionaryProvider dictionary={dictionary}>
            <SectionProvider paths={pageContext.sectionPaths}>
              <GlobalStyle />
              <ErrorBoundary FallbackComponent={ErrorPage}>
                <ThemeProvider theme={themeHeader}>
                  <Header desktopForeground={desktopForeground} mobileForeground={mobileForeground} monochromeLogo={monochromeLogo} />
                </ThemeProvider>
                <Container>{children}</Container>
                <Footer social={navigation!.social as DatoCmsNavigation['social']} />
                {popups.map(popup => <Popup key={popup.id} {...popup} />)}
              </ErrorBoundary>
            </SectionProvider>
          </DictionaryProvider>
        </LocaleProvider>
      </ThemeProvider>
    </BrandProvider>
  );
};

export default Page;
