import Color from 'color';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { Link, LinkInternal } from '../../core/link';

const units = (multiplier: number) => getUnitAsPixels({ multiplier });
const spaces = (multiplier: number) => getSpacerAsPixels({ multiplier });

export const Overlay = styled(motion.div)`
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
`;

export const Container = styled(motion.div)<{ $maxWidth: number; $rtl: boolean }>`
  position: fixed;
  z-index: 12;
  top: 0;
  ${({$rtl}) => ($rtl ? 'left: 0' : 'right: 0')};
  width: 100%;
  height: 100%;
  max-width: ${({$maxWidth}) => $maxWidth}px;
  background-color: ${({ theme }) => theme.background.hex};
  color: ${({ theme }) => theme.primary.hex};
  overflow-y: scroll;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;

  // hide scrollbar
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

export const Content = styled.div`
  padding: ${units(2)} ${units(3)};
  padding-bottom: ${spaces(2)};
  flex: 1;

  ${getMediaQuery(Size.MEDIUM)} {
    padding: ${units(4)} ${spaces(1)};
  }
`;

export const Strip = styled.img`
  display: block;
  width: 100%;
  height: 30px;
  object-fit: cover;
`

export const SubNav = styled.ul`
  display: flex;
  gap: ${getUnitAsPixels()};
  padding-bottom: ${getUnitAsPixels({multiplier:4})};
`

export const SubNavItem = styled.ul<{ selected: boolean }>`
  ${getTypography(Type.LABEL_1)};
  opacity: ${({selected}) => selected ? 1 : 0.3};
  cursor: pointer;
`

export const LinkList = styled.ul`
  margin-bottom: ${getUnitAsPixels({multiplier:4})};
  padding-bottom: ${getUnitAsPixels({multiplier:4})};
  border-bottom: 1px solid ${({ theme }) => Color(theme.primary.hex).alpha(0.2).toString()};
`

export const LinkListItem = styled.li`
  margin-bottom: ${getUnitAsPixels()};
  display: flex;
  align-items: center;
  &:last-child{
    margin-bottom: 0;
  }
`

export const LinkListLink = styled(Link)`
  ${getTypography(Type.NAV_1)};
  color: ${({ theme }) => theme.primary.hex};
  text-decoration: none;
  overflow: hidden;
  transition: color 0.3s;

  &:hover{
    color: ${({ theme }) => theme.secondary.hex};
  }

  &.active{
    color: ${({ theme }) => theme.secondary.hex};
  }
`

export const LinkListLinkSmall = styled(Link)`
  ${getTypography(Type.LABEL_2)};
  color: ${({ theme }) => theme.primary.hex};
  text-decoration: none;
  overflow: hidden;

  &:hover{
    color: ${({ theme }) => theme.secondary.hex};
  }

  &.active{
    color: ${({ theme }) => theme.secondary.hex};
  }
`

export const Tag = styled.div`
  background-color: ${({ theme }) => theme.secondary.hex};
  color: ${({ theme }) => theme.background.hex};
  ${getTypography(Type.TAG_1)};
  line-height: 19px;
  padding: 0 ${getUnitAsPixels({multiplier:0.5})};
  margin-inline-end: ${getUnitAsPixels()};
  display: inline-block;
  vertical-align: middle;
`;

//////////////////////////////////////






export const MenuRoot = styled(motion.div)<{ maxWidth: number; rtl: boolean }>`
  position: fixed;
  z-index: 12;
  top: 0;
  ${(props) => (props.rtl ? 'left: 0' : 'right: 0')};
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.maxWidth}px;
  background-color: ${({ theme }) => theme.background.hex};
  color: ${({ theme }) => theme.primary.hex};
  overflow-y: scroll;
  overscroll-behavior: contain;

  // hide scrollbar
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;



// tried button, preflight/normalize?
export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${units(4)} ${spaces(1)};
`;

export const LinksList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: ${units(3)} 0 0;
`;

export const LinksListItem = styled.li``;

export const LinksListItemLink = styled(LinkInternal)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: ${units(1)};
  ${getTypography(Type.NAV_1)};
  color: ${({ theme }) => theme.primary.hex};
  &:hover {
    color: ${({ theme }) => theme.secondary.hex};
  }
`;

export const SocialLink = styled(LinksListItemLink)`
  ${getTypography(Type.LABEL_2)};
`;

export const HR = styled.hr`
  border: 0;
  height: 1px;
  background-color: #ffffff40;
  margin-top: ${units(4)};
`;

export const LabelBold = styled.h1`
  ${getTypography(Type.LABEL_1)};
`;


export const Illustration = styled.img`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${units(3.75)};
  object-fit: cover;
`;