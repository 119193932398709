import styled from 'styled-components';
import { CONTAINER, getLayout, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, Layout, Size, VerticalSpaceVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { Link } from '../../core/link';

export const Container = styled.div`
  ${CONTAINER}
  background-color: ${({ theme }) => theme.colors.footer.background.hex};
`;

export const Content = styled.div`
  ${getLayout(Layout.GRID)}
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  align-items: flex-end;

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`

export const LinkList = styled.ul`
  grid-column: 1 / span 12;

  ${getMediaQuery(Size.LARGE)}{
    grid-column: span 6;
  }
`

export const LinkListItem = styled.li`
  margin-bottom: ${getUnitAsPixels({multiplier:2})};

  &:last-child{
    margin-bottom: 0;
  }
`

export const LinkListLink = styled(Link)`
  ${getTypography(Type.LARGE_HEADING)}
  color: ${({ theme }) => theme.colors.footer.foreground.hex};
  transition: opacity 0.3s;

  &:hover{
    opacity: 0.7;
  }
`

export const Strip = styled.img`
  display: block;
  width: 100%;
  height: 24px;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;

  ${getMediaQuery(Size.MEDIUM)}{
    height: 30px;
  }
`

export const Legal = styled.div`
  ${getTypography(Type.BODY_2)}
  color: ${({ theme }) => theme.colors.footer.foreground.hex};
  opacity: 0.5;
  grid-column: 1 / span 12;
  margin-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: span 6;
    text-align: end;
    margin-top: 0;
  }
`