import { property, size } from "lodash";
import { getWindowWidth, pixelate, round } from "./helpers";

export enum Size {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  XLARGE = "XLARGE",
  MAX = "MAX",
}

export enum Layout {
  GRID = "GRID",
  BLOCK = "BLOCK",
}

export enum VerticalSpaceVariant {
  LARGE = "LARGE",
  REGULAR = "REGULAR"
}

export const sizesOrdered = [
  Size.SMALL,
  Size.MEDIUM,
  Size.LARGE,
  Size.XLARGE,
  Size.MAX
]

export const unit:number = 8;
export const spacer:number = unit * 5

export const breakpoint: {[key in Size]:number} = {
  [Size.SMALL]: 0,
  [Size.MEDIUM]: 768,
  [Size.LARGE]: 1024,
  [Size.XLARGE]: 1400,
  [Size.MAX]: 2000,
};

export const margin: {[key in Size]:number} = {
  [Size.SMALL]: 16,
  [Size.MEDIUM]: 64,
  [Size.LARGE]: 64,
  [Size.XLARGE]: 80,
  [Size.MAX]: 80,
};

export const gutter: {[key in Size]:number} = {
  [Size.SMALL]: 16,
  [Size.MEDIUM]: 20,
  [Size.LARGE]: 24,
  [Size.XLARGE]: 36,
  [Size.MAX]: 36,
};

export const columns: {[key in Size]:number} = {
  [Size.SMALL]: 8,
  [Size.MEDIUM]: 12,
  [Size.LARGE]: 12,
  [Size.XLARGE]: 12,
  [Size.MAX]: 12,
};

export const header: {[key in Size]:number} = {
  [Size.SMALL]: 50,
  [Size.MEDIUM]: 64,
  [Size.LARGE]: 80,
  [Size.XLARGE]: 80,
  [Size.MAX]: 80,
};

export const verticalSpaceLarge: {[key in Size]:number} = {
  [Size.SMALL]: 40,
  [Size.MEDIUM]: 64,
  [Size.LARGE]: 104,
  [Size.XLARGE]: 104,
  [Size.MAX]: 104,
};

export const verticalSpaceRegular: {[key in Size]:number} = {
  [Size.SMALL]: 32,
  [Size.MEDIUM]: 40,
  [Size.LARGE]: 80,
  [Size.XLARGE]: 80,
  [Size.MAX]: 80,
};

export const verticalSpace: {[key in VerticalSpaceVariant]} = {
  [VerticalSpaceVariant.REGULAR]: verticalSpaceRegular,
  [VerticalSpaceVariant.LARGE]: verticalSpaceLarge,
};

export const getSizesOrdered = () => sizesOrdered.slice();

export const getColumns = (size:Size):number => columns[size];

export const getMediaQuery = (size:Size):string => `@media screen and (min-width: ${breakpoint[size]}px)`

export const getImageWidthAsStyle = (size:Size, {span,total=12,margins=0,gutters=0}):string => {
	return `calc((((${size === Size.MAX ? getMaxWidthAsPixels() : `100vw`} - ${getMarginAsPixels(size,{multiplier:2})}) * ${round(1 / total)}) * ${span ? span : total}) - ${getGutterAsPixels(size)} ${margins > 0 && gutters > 0 ? ` + ${pixelate(getMarginAsNumber(size,{multiplier:margins}) + getGutterAsNumber(size,{multiplier:gutters}))}` : ''})`;
}


//NUMBER
export const getBreakpointAsNumber = (size:Size):number => breakpoint[size];

export const getMarginAsNumber = (size:Size,{multiplier=1}:{multiplier?:number}={multiplier:1}):number => margin[size] * multiplier;

export const getGutterAsNumber = (size:Size,{multiplier=1}:{multiplier?:number}={multiplier:1}):number => gutter[size] * multiplier;

export const getHeaderAsNumber = (size:Size):number => header[size];

export const getMaxWidthAsNumber = ():number => breakpoint[Size.MAX];

export const getSpacerAsNumber = ({multiplier=1}:{multiplier?:number}={multiplier:1}):number => spacer * multiplier;

export const getUnitAsNumber = ({multiplier=1}:{multiplier?:number}={multiplier:1}):number => unit * multiplier;

export const getVerticalSpaceAsNumber = (size:Size,variant:VerticalSpaceVariant,{multiplier=1}:{multiplier?:number}={multiplier:1}):number => verticalSpace[variant][size] * multiplier;


//PIXELATED
export const getBreakpointAsPixels = (size:Size):string => pixelate(getBreakpointAsNumber(size));

export const getMarginAsPixels = (size:Size,{multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getMarginAsNumber(size,{multiplier}));

export const getGutterAsPixels = (size:Size,{multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getGutterAsNumber(size,{multiplier}));

export const getHeaderAsPixels = (size:Size):string => pixelate(header[size]);

export const getMaxWidthAsPixels = ():string => pixelate(getMaxWidthAsNumber());

export const getSpacerAsPixels = ({multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getSpacerAsNumber({multiplier}));

export const getUnitAsPixels = ({multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getUnitAsNumber({multiplier}));

export const getVerticalSpaceAsPixels = (size:Size,variant:VerticalSpaceVariant,{multiplier=1}:{multiplier?:number}={multiplier:1}):string => pixelate(getVerticalSpaceAsNumber(size,variant,{multiplier}));


export const getColumnWidthAsNumber = (size:Size,span:number,windowWidth?:number):number => {
  const contentWidth = Math.min(windowWidth || getMaxWidthAsNumber(),getMaxWidthAsNumber());
  const gutter = getGutterAsNumber(size);
  const margin = getMarginAsNumber(size);
  const columns = getColumns(size);
  return (((contentWidth + gutter - (margin * 2)) / columns) * span) - gutter;
}

export const getColumnsWidthAsStyle = (size:Size,span:number,length:number):string => {
  if(size === Size.MAX){
    return pixelate(getColumnWidthAsNumber(size,span * length));
  }
  return `calc((((100vw + ${getGutterAsPixels(size)} - ${getMarginAsPixels(size,{multiplier:2})}) / ${getColumns(size)}) * ${span * length}) - ${getGutterAsPixels(size)})`;
}

export const getIndentWidthAsStyle = (size:Size,span:number):string => {
  return span ? `calc((((100vw + ${getGutterAsPixels(size)} - ${getMarginAsPixels(size,{multiplier:2})}) / ${getColumns(size)}) * ${span}) - ${getGutterAsPixels(size)})` : `0`;
}

export const getImageSizes = (sizes:any = {},{defaultSize="100vw"}:{defaultSize?:string} = {defaultSize:"100vw"}) => {
	let props:object|null = null;
	let result:string = defaultSize;
	let imageSize:string;

	sizesOrdered.forEach(size => {
		props = sizes[size] ? {...props,...sizes[size]} : props;
		if(props){
			imageSize = getImageWidthAsStyle(size,props);
			result = `(min-width: ${getBreakpointAsPixels(size)}) ${imageSize}, ${result}`;
		}
	});

	return result;
}

export const getGridAtSize = (size:Size,columns?:number):string => `
  grid-template-columns: repeat(${columns || getColumns(size)}, [col-start] 1fr);
  column-gap: ${getGutterAsPixels(size)};
`

export const CONTAINER = `
  width: 100%;
  padding-left: ${getMarginAsPixels(Size.SMALL)};
  padding-right: ${getMarginAsPixels(Size.SMALL)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-left: ${getMarginAsPixels(Size.MEDIUM)};
    padding-right: ${getMarginAsPixels(Size.MEDIUM)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-left: ${getMarginAsPixels(Size.LARGE)};
    padding-right: ${getMarginAsPixels(Size.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-left: ${getMarginAsPixels(Size.XLARGE)};
    padding-right: ${getMarginAsPixels(Size.XLARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-left: ${getMarginAsPixels(Size.MAX)};
    padding-right: ${getMarginAsPixels(Size.MAX)};
  }
`;

export const GRID = `
  display: -ms-grid;
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  -ms-grid-columns: repeat(${getColumns(Size.SMALL)}, [col-start] 1fr);
  grid-template-columns: repeat(${getColumns(Size.SMALL)}, [col-start] 1fr);
  column-gap: ${getGutterAsPixels(Size.SMALL)};

  ${getMediaQuery(Size.MEDIUM)}{
    -ms-grid-columns: repeat(${getColumns(Size.MEDIUM)}, [col-start] 1fr);
    grid-template-columns: repeat(${getColumns(Size.MEDIUM)}, [col-start] 1fr);
    column-gap: ${getGutterAsPixels(Size.MEDIUM)};
  }

  ${getMediaQuery(Size.LARGE)}{
    -ms-grid-columns: repeat(${getColumns(Size.LARGE)}, [col-start] 1fr);
    grid-template-columns: repeat(${getColumns(Size.LARGE)}, [col-start] 1fr);
    column-gap: ${getGutterAsPixels(Size.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    -ms-grid-columns: repeat(${getColumns(Size.XLARGE)}, [col-start] 1fr);
    grid-template-columns: repeat(${getColumns(Size.XLARGE)}, [col-start] 1fr);
    column-gap: ${getGutterAsPixels(Size.XLARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    -ms-grid-columns: repeat(${getColumns(Size.MAX)}, [col-start] 1fr);
    grid-template-columns: repeat(${getColumns(Size.MAX)}, [col-start] 1fr);
    column-gap: ${getGutterAsPixels(Size.MAX)};
  }
`

export const GRID_LAYOUT = `
  ${GRID}
  max-width: ${pixelate(getMaxWidthAsNumber() - getMarginAsNumber(Size.MAX,{multiplier:2}))};
  margin-left: auto;
  margin-right: auto;
`

export const BLOCK_LAYOUT = `
  display: block;
  width: 100%;
  max-width: ${pixelate(getMaxWidthAsNumber() - getMarginAsNumber(Size.MAX,{multiplier:2}))};
  margin-left: auto;
  margin-right: auto;
`

const layouts: {[key in Layout]:string} = {
  [Layout.GRID]: GRID_LAYOUT,
  [Layout.BLOCK]: BLOCK_LAYOUT,
};

export const getLayout = (layout:Layout):any => layouts[layout]

export const getLayoutByName = (name:string):any => {
  if(name){
      switch(name.toUpperCase()){
          case 'GRID': return layouts[Layout.GRID];
          case 'BLOCK': return layouts[Layout.BLOCK];
      }
  }
  
  return layouts[Layout.BLOCK];
}