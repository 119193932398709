import { useEffect, useState } from 'react';
import { getBreakpointAsNumber, getSizesOrdered, Size } from '../theme/layout';

const getCurrentSize = ():Size | undefined => {
	const sizes = getSizesOrdered();
  const width = getWidth();

	if(width){
    return sizes.reverse().find(size => width >= getBreakpointAsNumber(size))
  }
  
  return undefined;
}

function getWidth():number | undefined{
	if(typeof window !== 'undefined'){
		return window.innerWidth;
	}

	return undefined;
}

export function useCurrentScreenSize({wait=100}={wait:100}) {
	const [size, setSize] = useState(getCurrentSize());
	
	let throttleTimeout: number | null = null;

	const update = () => {
		throttleTimeout = null;
		setSize(getCurrentSize());
	}

	useEffect(() => {
		const change = () => {
			if (wait) {
				if (throttleTimeout === null) {
					throttleTimeout = window.setTimeout(update, wait)
				}
			} else {
				update()
			}
		}
		
		if(typeof window !== 'undefined'){
			window.addEventListener('resize', change);
			return () => {
				window.removeEventListener('resize', change);
			};
		}
	},[]);

	return size;
}