import styled from "styled-components";
import { getMediaQuery, getUnitAsPixels, getVerticalSpaceAsPixels, Size, VerticalSpaceVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";

export const Container = styled.div`
  grid-column: 1 / span 8;
  border-top: 2px solid ${({theme}) => theme.primary.hex};
  color: ${({theme}) => theme.primary.hex};
  padding-top: ${getUnitAsPixels()};
  padding-bottom: ${getUnitAsPixels({multiplier:4})};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }
  
  ${getMediaQuery(Size.LARGE)}{
    border-top: none;
    grid-column: 1 / span 3;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const Heading = styled.h2<{light?:boolean}>`
  ${({light}) => light ? getTypography(Type.SECTION_HEADING_LIGHT) : getTypography(Type.SECTION_HEADING)}
`;

export const Content = styled.div`
  ${getMediaQuery(Size.LARGE)}{
    position: sticky;
    top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`;

export const Subheading = styled.h3`
  ${getTypography(Type.LABEL_2)}
  margin-top: ${getUnitAsPixels({multiplier:1})};
`;

export const ExpandableButton = styled.button<{expanded:boolean}>`
  ${getTypography(Type.LABEL_2)}
  margin-top: ${getUnitAsPixels({multiplier:2})};
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:before{
    content: "";
    width: 15px;
    height: 15px;
    margin-inline-end: ${getUnitAsPixels()};

    ${({expanded}) => expanded ? `
      background: linear-gradient(#000,#000);
      background-position:center;
      background-size: 15px 1px;
    ` : `
      background: linear-gradient(#000,#000),linear-gradient(#000,#000);
      background-position:center;
      background-size: 15px 1px,1px 15px;
    `}
    
    background-repeat:no-repeat;
  }
`