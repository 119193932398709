import { motion, MotionProps } from 'framer-motion';
import React, { HTMLProps } from 'react';

const Path = (props: any) => (
  <motion.path fill="transparent" strokeWidth={2} strokeLinecap="square" {...props} />
);

type Props = MotionProps &
  Pick<HTMLProps<HTMLButtonElement>, 'className' | 'onClick'> & {
    isOpen: boolean;
  };

export const BurgerIcon = ({ isOpen, ...rest }: Props) => (
  <motion.button {...rest}>
    <svg width="1em" height="1em" viewBox="0 0 18 18">
      <Path
        initial={'closed'}
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        initial={'closed'}
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        initial={'closed'}
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </motion.button>
);

export const SvgHamburger = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 1h20M0 8.901h20M0 17h20" strokeWidth={2} />
    </svg>
  );
};
