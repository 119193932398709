import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useBrandTheme } from '../../../context/brand';

import { Container, Content, Heading, Inner, Text } from './errorMessage.styles';

export const ErrorMessage = ({heading,text}:{heading:string,text:string}) => {
    const theme = useBrandTheme("error");
    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Content>
                    <Inner>
                        <Heading>{heading}</Heading>
                        <Text>{text}</Text>
                    </Inner>
                </Content>
            </Container>
        </ThemeProvider>
    )
};