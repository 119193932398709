import styled, { css } from 'styled-components';
import { Font, getFont } from './font';
import { getMediaQuery, Size } from './layout';

export enum Type {
  HEADLINE = 'HEADLINE',
  SECTION_HEADING = 'SECTION_HEADING',
  SECTION_HEADING_LIGHT = 'SECTION_HEADING_LIGHT',
  SMALL_HEADING = 'SMALL_HEADING',
  MEDIUM_HEADING = 'MEDIUM_HEADING',
  LARGE_HEADING = 'LARGE_HEADING',

  BODY_1 = 'BODY_1',
  BODY_2 = 'BODY_2',
  LABEL_1 = 'LABEL_1',
  LABEL_2 = 'LABEL_2',
  LABEL_3 = 'LABEL_3',
  BUTTON_1 = 'BUTTON_1',
  BUTTON_2 = 'BUTTON_2',
  NAV_1 = 'NAV_1',
  TAG_1 = 'TAG_1',
}

const typography: { [key in Type]: any } = {
  HEADLINE: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase;
    text-decoration: none;

    ${getMediaQuery(Size.MEDIUM)} {
      font-size: 68px;
      line-height: 78px;
    }

    ${getMediaQuery(Size.LARGE)} {
      font-size: 80px;
      line-height: 92px;
    }
  `,
  SECTION_HEADING: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase;
    text-decoration: none;

    ${getMediaQuery(Size.XLARGE)} {
      font-size: 26px;
      line-height: 28px;
    }
  `,
  SECTION_HEADING_LIGHT: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase;
    text-decoration: none;

    ${getMediaQuery(Size.XLARGE)} {
      font-size: 26px;
      line-height: 28px;
    }
  `,
  SMALL_HEADING: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    text-decoration: none;
  `,
  MEDIUM_HEADING: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    text-decoration: none;

    ${getMediaQuery(Size.XLARGE)} {
      font-size: 26px;
      line-height: 32px;
    }
  `,
  LARGE_HEADING: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
    text-decoration: none;

    ${getMediaQuery(Size.LARGE)} {
      font-size: 36px;
      line-height: 42px;
    }
    
    ${getMediaQuery(Size.XLARGE)} {
      font-size: 60px;
      line-height: 70px;
    }
  `,

  BODY_1: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 30px;
    
    ${getMediaQuery(Size.LARGE)} {
      font-size: 30px;
      line-height: 46px;
    }

    strong{
      font-weight: 700;
    }
  `,
  BODY_2: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    ${getMediaQuery(Size.LARGE)} {
      font-size: 20px;
      line-height: 32px;
    }

    strong{
      font-weight: 700;
    }
  `,
  LABEL_1: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none;

    ${getMediaQuery(Size.XLARGE)} {
      font-size: 15px;
    }
  `,
  LABEL_2: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none;

    ${getMediaQuery(Size.XLARGE)} {
      font-size: 15px;
      line-height: 18px;
    }
  `,
  LABEL_3: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none;
  `,
  BUTTON_1: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    text-transform: uppercase;
    text-decoration: none;
  `,
  BUTTON_2: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    text-transform: uppercase;
    text-decoration: none;
  `,
  NAV_1: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    text-decoration: none;

    ${getMediaQuery(Size.LARGE)} {
      font-size: 26px;
      line-height: 32px;
    }
  `,
  TAG_1: css`
    font-family: ${getFont(Font.PRIMARY)};
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    text-decoration: none;
  `,
};

export const getTypography = (type: Type): any => typography[type];

export const getTypographyComponent = (type: Type): any =>
  styled.div`
    ${getTypography(type)}
  `;
