import React, { useEffect, useImperativeHandle, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { daysAfterTimestamp } from '../../../common/date';
import { useIsRTL } from '../../../context/locale';
import { useEscButton } from '../../../hooks/useEscButton.hooks';
import { Mailchimp } from '../../core/mailchimp';
import { Portal } from '../../layout/portal';
import { usePopupStatus } from './popup.hooks';

import { Container, Heading, Illustration, IllustrationContainer, ContentContainer, Inner, Text, CloseButton } from './popup.styles';
import { PopupProps, PopupStatus } from './popup.types';

export const Popup = ({originalId,heading,text,illustration,delay,theme,mailchimp}:PopupProps) => {
  
  const [status, setStatus] = usePopupStatus(originalId);

  const [visible, setVisible] = useState(false);
  const isRTL = useIsRTL();

  let timeout;
  useEffect(() => {
    if(status && status.value === PopupStatus.INITIALIZED || (status.value !== PopupStatus.COMPLETED && daysAfterTimestamp(status.timestamp) >= 1)){
      timeout = setTimeout(() => {
        setStatus({
          value: PopupStatus.VIEWED,
          timestamp: Date.now()
        })
        setVisible(true);
      },delay * 1000);
    }

    return () => {
      if(timeout){
        clearTimeout(timeout);
      }
    }
  },[]);

  const handleHide = () => {
    if(status.value !== PopupStatus.COMPLETED){
      setStatus({
        value: PopupStatus.DISMISSED,
        timestamp: Date.now()
      })
    }
    setVisible(false);
    document.body.style.overflow = 'unset';
  };

  const handleContainerClick = (event:MouseEvent) => {
    if(event.target === event.currentTarget){
      handleHide();
    }
  }

  const handleCloseClick = (event:MouseEvent) => {
    handleHide();
  }

  const handleMailchimpSubmitted = () => {
    setStatus({
      value: PopupStatus.COMPLETED,
      timestamp: Date.now()
    })
  }

  useEffect(() => {
    return () => handleHide();
  }, []);

  useEscButton(() => handleHide());

  if(visible){
    return (
      <Portal>
        <Container onClick={handleContainerClick}>
          <ThemeProvider theme={theme}>
            <Inner>
              <ContentContainer>
                <Heading>{heading}</Heading>
                <Text>{text}</Text>
                {mailchimp && <Mailchimp {...mailchimp} onSubmitted={handleMailchimpSubmitted} />}
              </ContentContainer>
              <IllustrationContainer>
                {illustration && <Illustration src={illustration.url} />}
              </IllustrationContainer>
  
              <CloseButton isRTL={isRTL} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCloseClick}>
                <path d="M1 1L19 19" strokeWidth="2"/>
                <path d="M19 1L0.999999 19" strokeWidth="2"/>
              </CloseButton>
            </Inner>
            
          </ThemeProvider>
        </Container>
      </Portal>
    )
  }

  return null;
  
}