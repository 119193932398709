import React from 'react';
import { ButtonProps, ButtonSize } from './button.types';
import { Container } from './button.styles';
import NewWindowIcon from '../../../images/new-window.svg';

export const Button = ({link,variant,size=ButtonSize.MEDIUM}:ButtonProps) => {
  const icon = (
    <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.43075 15.4714L13.8455 8.04386L13.8327 13.7711H15.2262V5.66602H7.13388L7.12109 7.0467H12.8484L5.43359 14.4743L6.43075 15.4714Z" />
    </svg>
  )

  return (
    <Container link={link} icon={icon} variant={variant} size={size} />
  )
}