import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { getHeaderAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { Link } from '../../core/link';
import { GatsbyLink } from '../../core/gatsbyLink';

const units = (multiplier: number) => getUnitAsPixels({ multiplier });
const spaces = (multiplier: number) => getSpacerAsPixels({ multiplier });

export const Container = styled(motion.header)``;

const barStyles = css`
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: ${getHeaderAsPixels(Size.SMALL)};

  ${getMediaQuery(Size.MEDIUM)} {
    height: ${getHeaderAsPixels(Size.MEDIUM)};
  }

  ${getMediaQuery(Size.LARGE)} {
    height: ${getHeaderAsPixels(Size.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)} {
    height: ${getHeaderAsPixels(Size.XLARGE)};
  }

  ${getMediaQuery(Size.MAX)} {
    height: ${getHeaderAsPixels(Size.MAX)};
  }
`

export const FixedBar = styled.div`
  position: absolute;
  z-index: 10;
  ${barStyles}
`

export const StickyBar = styled(motion.div)`
  position: fixed;
  z-index: 11;
  background-color: ${({ theme }) => theme.colors.bar.background.hex};
  ${barStyles}
`;

export const Background = styled.div<{ isTop: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bar.background.hex};
  opacity: ${({ isTop }) => isTop ? 0 : 1};
`

export const Branding = styled.div`
  display: flex;
  position: relative;
`

export const LogoIcon = styled.div<{ icon: string }>`
  flex: 1;
  mask-image: url(${({icon}) => icon});
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 24px;

  ${getMediaQuery(Size.MEDIUM)} {
    mask-size: 30px;
  }

  ${getMediaQuery(Size.LARGE)} {
    mask-size: 38px;
  }
`

export const Logo = styled(GatsbyLink)<{ isTop: boolean, isMonochrome: boolean, desktopForeground: {hex:string}, mobileForeground: {hex:string}}>`
  font-size: ${units(3)};
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  background-color: ${({ theme, isTop, isMonochrome, mobileForeground }) => isTop && isMonochrome ? mobileForeground.hex : theme.colors.logo.background.hex};
  height: ${getHeaderAsPixels(Size.SMALL)};
  width: ${getHeaderAsPixels(Size.SMALL)};
  
  ${getMediaQuery(Size.MEDIUM)} {
    height: ${getHeaderAsPixels(Size.MEDIUM)};
    width: ${getHeaderAsPixels(Size.MEDIUM)};
    font-size: ${units(4.5)};
  }

  ${getMediaQuery(Size.LARGE)} {
    background-color: ${({ theme, isTop, isMonochrome, desktopForeground }) => isTop && isMonochrome ? desktopForeground.hex : theme.colors.logo.background.hex};
    height: ${getHeaderAsPixels(Size.LARGE)};
    width: ${getHeaderAsPixels(Size.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)} {
    height: ${getHeaderAsPixels(Size.XLARGE)};
    width: ${getHeaderAsPixels(Size.XLARGE)};
  }

  ${getMediaQuery(Size.MAX)} {
    height: ${getHeaderAsPixels(Size.MAX)};
    width: ${getHeaderAsPixels(Size.MAX)};
  }

  // IE hack
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: ${({ theme }) => theme.colors.logo.background.hex};
  }

  ${LogoIcon}{
    background-color: ${({ theme, isTop, isMonochrome }) => isTop && isMonochrome ? theme.background.hex : theme.colors.logo.foreground.hex};

    ${getMediaQuery(Size.LARGE)} {

    }
  }
`;

export const LockupImage = styled.div<{ icon: string, rtl: boolean }>`
  flex: 1;
  mask-image: url(${({icon}) => icon});
  mask-position: ${({rtl}) => rtl ? "right" : "left"};
  mask-repeat: no-repeat;
  mask-size: contain;
  width: 64px;
  height: 100%;

  ${getMediaQuery(Size.MEDIUM)} {
    width: 94px;
  }
`

export const Lockup = styled(GatsbyLink)<{ isTop: boolean, isLight: boolean, desktopForeground: {hex:string}, mobileForeground: {hex:string}}>`
  display: block;
  padding: ${getUnitAsPixels({multiplier:1.5})} ${getUnitAsPixels({multiplier:2})};

  ${getMediaQuery(Size.LARGE)} {
    padding: ${getUnitAsPixels({multiplier:2.5})} ${getUnitAsPixels({multiplier:3})};
  }
  
  ${LockupImage}{
    background-color: ${({ theme, isTop, mobileForeground }) => isTop ? mobileForeground.hex : theme.colors.bar.foreground.hex};

    ${getMediaQuery(Size.LARGE)} {
      background-color: ${({ theme, isTop, desktopForeground }) => isTop ? desktopForeground.hex : theme.colors.bar.foreground.hex};
    }
  }
`

export const LinkListLink = styled(Link)`
  ${getTypography(Type.LABEL_1)}
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    opacity: 0;
  }

  &:hover{
    &:before{
      opacity: 1;
    }
  }

  &.active{
    &:before{
      opacity: 1;
    }
  }
`

export const LinkList = styled.ul<{ isTop: boolean, isLight: boolean, desktopForeground: {hex:string}, mobileForeground: {hex:string}}>`
  display: none;
  
  ${LinkListLink}{
    color: ${({ theme, isTop, mobileForeground }) => isTop ? mobileForeground.hex : theme.colors.bar.foreground.hex};

    ${getMediaQuery(Size.LARGE)} {
      color: ${({ theme, isTop, desktopForeground }) => isTop ? desktopForeground.hex : theme.colors.bar.foreground.hex};
    }

    &:before{
      background-color: ${({ theme, isTop, isLight, mobileForeground }) => isTop && !isLight ? mobileForeground.hex : theme.colors.logo.background.hex};

      ${getMediaQuery(Size.LARGE)} {
        background-color: ${({ theme, isTop, isLight, desktopForeground }) => isTop && !isLight ? desktopForeground.hex : theme.colors.logo.background.hex};
      }
    }
  }

  ${getMediaQuery(Size.LARGE)} {
    display: flex;
  }
`

export const LinkListItem = styled.li`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  padding: 0 ${getUnitAsPixels({multiplier:1.5})};
`

const actionIconStyles = css`
  padding: ${getUnitAsPixels({multiplier:1})};
  font-size: ${units(2)};
  cursor: pointer;
  
  ${getMediaQuery(Size.MEDIUM)} {
    font-size: ${units(2.5)};
  }

  ${getMediaQuery(Size.LARGE)} {
    padding: ${getUnitAsPixels({multiplier:1.5})};
  }
`;

export const MenuButton = styled(motion.div)<{ $isTop: boolean, $isOpen: boolean, $desktopForeground: {hex:string}, $mobileForeground: {hex:string}}>`
  ${actionIconStyles}
  position: relative;
  z-index: 12;
  transition: stroke 0.4s;
  stroke: ${({ theme, $isTop, $isOpen, $mobileForeground }) => $isOpen ? theme.colors.common.light.hex : $isTop ? $mobileForeground.hex : theme.colors.bar.foreground.hex};

  ${getMediaQuery(Size.LARGE)} {
    stroke: ${({ theme, $isTop, $isOpen, $desktopForeground }) => $isOpen ? theme.colors.common.light.hex : $isTop ? $desktopForeground.hex : theme.colors.bar.foreground.hex};
  }
`;

export const SearchButton = styled(motion.div)<{ $isTop: boolean, $isOpen: boolean, $desktopForeground: {hex:string}, $mobileForeground: {hex:string}}>`
  ${actionIconStyles}
  position: relative;
  z-index: 12;
  transition: stroke 0.4s;
  stroke: ${({ theme, $isTop, $mobileForeground }) => $isTop ? $mobileForeground.hex : theme.colors.bar.foreground.hex};

  ${getMediaQuery(Size.LARGE)} {
    stroke: ${({ theme, $isTop, $desktopForeground }) => $isTop ? $desktopForeground.hex : theme.colors.bar.foreground.hex};
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 0 ${getUnitAsPixels({multiplier:1})};
  align-items: center;

  ${getMediaQuery(Size.MEDIUM)} {
    padding: 0 ${getUnitAsPixels({multiplier:2})};
  }

  ${getMediaQuery(Size.LARGE)} {
    padding: 0 ${getUnitAsPixels({multiplier:3.5})};
  }
`

export const Overlay = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
`;
