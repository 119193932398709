import styled from "styled-components"
import { getMediaQuery, getUnitAsPixels, Size } from "../../../theme/layout"
import { getTypography, Type } from "../../../theme/typography"
import { LinkInternal } from "../../core/link"
import { ResponsiveImage } from "../../core/responsiveMedia"

export const Heading = styled(LinkInternal)`
  ${getTypography(Type.SMALL_HEADING)}
  color: ${({ theme }) => theme.primary.hex};
  text-decoration: none;
  display: block;
  overflow: hidden;
  transition: color 0.3s;
  padding-bottom: ${getUnitAsPixels({multiplier:1})};

  &:hover{
    color: ${({theme}) => theme.secondary.hex};
  }
`

export const Label = styled.span`
  ${getTypography(Type.LABEL_2)}
  color: ${({ theme }) => theme.primary.hex};
  display: block;
  padding-bottom: ${getUnitAsPixels()};
`

export const Thumbnail = styled(ResponsiveImage)`
  user-select: none;
  position: relative;
  z-index: 1;
`

export const ThumbnailContainer = styled(LinkInternal)`
  position: relative;
  display: block;

  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 2;
  }

  &:hover{
    &:after{
      opacity: 1;
    }
  }
`

export const Badge = styled.span<{rtl:boolean}>`
  ${getTypography(Type.LABEL_3)}
  background-color: ${({ theme }) => theme.primary.hex};
  color: ${({ theme }) => theme.background.hex};
  position: absolute;
  bottom: 0;
  ${({ rtl }) => rtl ? `right: 0;` : `left: 0;`}
  padding: ${getUnitAsPixels()} ${getUnitAsPixels({multiplier:2})};
  z-index: 3;
`