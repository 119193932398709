import Color from 'color';
import styled from 'styled-components';
import { ThemeType } from '../../../theme/color';
import { getSpacerAsPixels, getUnitAsPixels } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { Link } from '../link';
import { ButtonSize, ButtonVariant } from './button.types';

export const Container = styled(Link)<{variant:ButtonVariant,size:ButtonSize}>`
  ${({variant,size}) => size === ButtonSize.SMALL || variant === ButtonVariant.TERTIARY ? getTypography(Type.BUTTON_2) : getTypography(Type.BUTTON_1)}
  padding: 0 ${({size}) => size === ButtonSize.SMALL ? getUnitAsPixels({multiplier:2}) : getSpacerAsPixels({multiplier:1})};
  height: ${({size}) => size === ButtonSize.SMALL ? getUnitAsPixels({multiplier:4}) : getUnitAsPixels({multiplier:7})};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: ${({theme,variant}) => variant === ButtonVariant.PRIMARY ? theme.primary.hex : `transparent`};
	color: ${({theme,variant}) => variant === ButtonVariant.PRIMARY ? theme.background.hex : theme.primary.hex};
  border: 1px solid ${({theme,variant}) => variant === ButtonVariant.TERTIARY ? Color(theme.primary.hex).alpha(0.2) : theme.primary.hex};
  fill: ${({theme,variant}) => variant === ButtonVariant.PRIMARY ? theme.background.hex : theme.primary.hex};
  text-align: center;
  white-space: nowrap;
  transition: background-color 0.3s, color 0.3s, border 0.3s, fill 0.3s;

  &:hover{
    background-color: ${({theme,variant}) => variant === ButtonVariant.PRIMARY ? theme.secondary.hex : variant === ButtonVariant.SECONDARY ? theme.primary.hex : `transparent`};
    color: ${({theme,variant}) => variant === ButtonVariant.PRIMARY || variant === ButtonVariant.SECONDARY ? theme.background.hex : theme.primary.hex};
    border: 1px solid ${({theme,variant}) => variant === ButtonVariant.PRIMARY ? theme.secondary.hex : theme.primary.hex};
    fill: ${({theme,variant}) => variant === ButtonVariant.PRIMARY || variant === ButtonVariant.SECONDARY ? theme.background.hex : theme.primary.hex};
  }

  > svg {
    margin-inline-start: ${getUnitAsPixels()};
    transform: scale(-1,1);
  }
`;