import React, { forwardRef, SVGProps } from 'react';

export const SvgSearch = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path d="M11.926 12.129L18.796 19" strokeWidth={2} />
      <circle cx={7.206} cy={7.206} r={6.206} strokeWidth={2} />
    </svg>
  );
});

export default SvgSearch;
