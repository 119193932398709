import styled from 'styled-components';
import { getColumnsWidthAsStyle, getGutterAsPixels, getIndentWidthAsStyle, getMediaQuery, Size } from '../../../theme/layout';
import { Indent, Span } from './slider.component';

export const Container = styled.div`
  overflow: hidden;
  touch-action: pan-y;
`;

export const Content = styled.div<{ 
  length: number, 
  span: Span,
  indent: Indent
}>` 
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(${({length}) => length}, [col-start] 1fr);
  column-gap: ${getGutterAsPixels(Size.SMALL)};
  width: ${({span,length}) => getColumnsWidthAsStyle(Size.SMALL,span[Size.SMALL],length)};
  margin-inline-start: ${({indent}) => getIndentWidthAsStyle(Size.SMALL,indent[Size.SMALL])};
  
  ${getMediaQuery(Size.MEDIUM)}{
    column-gap: ${getGutterAsPixels(Size.MEDIUM)};
    width: ${({span,length}) => getColumnsWidthAsStyle(Size.MEDIUM,span[Size.MEDIUM],length)};
    margin-inline-start: ${({indent}) => getIndentWidthAsStyle(Size.MEDIUM,indent[Size.MEDIUM])};
  }

  ${getMediaQuery(Size.LARGE)}{
    column-gap: ${getGutterAsPixels(Size.LARGE)};
    width: ${({span,length}) => getColumnsWidthAsStyle(Size.LARGE,span[Size.LARGE],length)};
    margin-inline-start: ${({indent}) => getIndentWidthAsStyle(Size.LARGE,indent[Size.LARGE])};
  }

  ${getMediaQuery(Size.XLARGE)}{
    column-gap: ${getGutterAsPixels(Size.XLARGE)};
    width: ${({span,length}) => getColumnsWidthAsStyle(Size.XLARGE,span[Size.XLARGE],length)};
    margin-inline-start: ${({indent}) => getIndentWidthAsStyle(Size.XLARGE,indent[Size.XLARGE])};
  }

  ${getMediaQuery(Size.MAX)}{
    column-gap: ${getGutterAsPixels(Size.MAX)};
    width: ${({span,length}) => getColumnsWidthAsStyle(Size.MAX,span[Size.MAX],length)};
    margin-inline-start: ${({indent}) => getIndentWidthAsStyle(Size.MAX,indent[Size.MAX])};
  }
`

export const Item = styled.div`
  grid-column: span 1;
`